import { deepFreeze } from 'utils';
import merge from 'deepmerge';

import devConfig from 'config.dev';
import prodConfig from 'config.prod';

const defaultConfig = {
	FLYTER: {
		CDN_BASE_URI: 'https://d288tgz79vj593.cloudfront.net',
	},
	MAPBOX_ACCESS_TOKEN: 'pk.eyJ1IjoiZmx5dGVyIiwiYSI6ImNrM3M2cnprajAyNGEzYnF2YnRiZmNlMDcifQ.7zV8biPJ50FDSw00jXBQ2A',
	GAPI: {
		CLIENT_ID: '334767324132-c9eqjhhdt6rv5qj35cj6bon9dgfg1s4m.apps.googleusercontent.com',
		API_KEY: 'AIzaSyBeB-Obel3L5IWB6-JH-EN1jY2s2nHL1Jo',
		// Array of API discovery doc URLs for APIs used by the quickstart
		DISCOVERY_DOCS: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
		// Authorization scopes required by the API; multiple scopes can be
		// included, separated by spaces.
		SCOPES: 'https://www.googleapis.com/auth/spreadsheets',
		GOOGLE_SHEET: {
			AIRPORTS_SPREADSHEET_ID: '1KWj1n9_AHidsl7SP-hg1adVw0RnwhT26_tUX094usu8',
			AIRPORTS_SHEET_NAME_AIRPORTS: 'Airports',
			AIRPORTS_SHEET_NAME_TERMINALS: 'Terminals',
			PLACES_FIELDS_SPREADSHEET_ID: '1_x8jZiikt8TA3KyFeK8Eab3ExgVa4YBAvYOBIP5ioDc',
			PLACES_FIELDS_SHEET_NAME_FIELDS: 'Fields',
			PLACES_FIELDS_SHEET_NAME_PLACES: 'Places',
		},
	},
	AIRTABLE: {
		BASE_URL: 'https://api.airtable.com/v0',
		API_KEY: 'keylJd7bdTqphbkn5',
		BASES: {
			PLACES: {
				ID: 'appsEvltuYoPTdE48',
				TABLES_NAMES: {
					PLACES: 'Places',
					CATEGORIES: 'Categories',
					FAMILIES: 'Families',
					ICONS: 'Icons',
				}
			},
			AIRPORTS: {
				ID: 'appgeiC4NtsMkDy8V',
				TABLES_NAMES: {
					AIRPORTS: 'Airports',
					MAPBOX_URL: 'Mapbox url',
					TERMINALS: 'Terminals',
					GATES: 'Gates',
					LEVELS: 'Levels',
					SECURITY_CHECKPOINTS: 'Security checkpoints',
				}
			},
		}
	}
};

const dev = merge(defaultConfig, devConfig);
const prod = merge(defaultConfig, prodConfig);

const config = process.env.NODE_ENV === 'development' ? dev : prod;
export default deepFreeze(config);
