import { createAction, createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import actionTypes from './actionTypes';


//TODO: fetch from airtable
import {
	fetchTerminals as fetchTerminalsApi,
	fetchSecurityCheckpoints as fetchSecurityCheckpointsApi,
	fetchAppConfig as fetchAppConfigApi,
} from 'Api/airtable';
import {
	fetchAirports as fetchAirportsApi,
	fetchAirport as fetchAirportApi,
	fetchPlaces as fetchPlacesApi,
	fetchCategories as fetchCategoriesApi,
} from 'Api/flyter';

export const gapiSetLoggedInStatus = createAction(actionTypes.GAPI_LOGGED_IN_STATUS);

export const fetchAppConfigAction = createAction(actionTypes.FETCH_APP_CONFIG);
export const fetchAppConfigSuccessAction = createAction(actionTypes.FETCH_APP_CONFIG_SUCCESS);
export function loadAppConfig() {
	return async (dispatch) => {
		dispatch(fetchAppConfigAction());
		const appConfig = await fetchAppConfigApi();
		dispatch(fetchAppConfigSuccessAction(appConfig));
	}
}

export const fetchAirportsAction = createAction(actionTypes.FETCH_AIRPORTS);
export const fetchAirportsSuccessAction = createAction(actionTypes.FETCH_AIRPORTS_SUCCESS);
export function loadAirports() {
	return async (dispatch) => {
		dispatch(fetchAirportsAction());
		const airports = await fetchAirportsApi();
		dispatch(fetchAirportsSuccessAction(airports));
	}
}

export const fetchPlacesAction = createAction(actionTypes.FETCH_PLACES);
export const fetchPlacesSuccessAction = createAction(actionTypes.FETCH_PLACES_SUCCESS);
export function loadPlaces() {
	return async (dispatch) => {
		dispatch(fetchPlacesAction());
		const places = await fetchPlacesApi();
		dispatch(fetchPlacesSuccessAction(places));
	}
}

export const fetchFieldsAction = createAction(actionTypes.FETCH_FIELDS);
export const fetchFieldsSuccessAction = createAction(actionTypes.FETCH_FIELDS_SUCCESS);
export function loadFields() {
	return async (dispatch) => {
		// dispatch(fetchFieldsAction());
		// const fields = await fetchFieldsApi();
		// dispatch(fetchFieldsSuccessAction(fields));
	}
}

export const fetchCategoriesAction = createAction(actionTypes.FETCH_CATEGORIES);
export const fetchCategoriesSuccessAction = createAction(actionTypes.FETCH_CATEGORIES_SUCCESS);
export function loadCategories() {
	return async (dispatch) => {
		dispatch(fetchCategoriesAction());
		const categories = await fetchCategoriesApi();
		dispatch(fetchCategoriesSuccessAction(categories));
	}
}

export const fetchSecurityCheckpointsAction = createAction(actionTypes.FETCH_SECURITY_CHECKPOINTS);
export const fetchSecurityCheckpointsSuccessAction = createAction(actionTypes.FETCH_SECURITY_CHECKPOINTS_SUCCESS);
export function loadSecurityCheckpoints(airportIata) {
	return async (dispatch) => {
		dispatch(fetchSecurityCheckpointsAction());
		const securityCheckpoints = await fetchSecurityCheckpointsApi(airportIata);
		dispatch(fetchSecurityCheckpointsSuccessAction(securityCheckpoints));
	}
}

export const selectAirportAction = createAction(actionTypes.SELECT_AIRPORT);
export const fetchAirportAction = createAction(actionTypes.FETCH_AIRPORT);
export const fetchAirportSuccessAction = createAction(actionTypes.FETCH_AIRPORT_SUCCESS);
// export const fetchTerminalsAction = createAction(actionTypes.FETCH_TERMINALS);
// export const fetchTerminalsSuccessAction = createAction(actionTypes.FETCH_TERMINALS_SUCCESS);

export function selectAirport(airportIATA) {
	return async (dispatch) => {
		dispatch(selectAirportAction(airportIATA));
		dispatch(fetchAirportAction());
		// dispatch(fetchTerminalsAction());
		const airportData = await fetchAirportApi(airportIATA);
		dispatch(fetchAirportSuccessAction(airportData));
	}
}

export const selectAirportTerminalAction = createAction(actionTypes.SELECT_TERMINAL);
export const fetchTerminalPlacesAction = createAction(actionTypes.FETCH_TERMINAL_PLACES);
export const fetchTerminalPlacesSuccessAction = createAction(actionTypes.FETCH_TERMINAL_PLACES_SUCCESS);
export function selectAirportTerminal(terminal) {
	return async (dispatch, getState) => {
		let airport = getState().airports.selectedAirport;
		// dispatch(selectAirportTerminalAction(terminal));
		// dispatch(fetchTerminalPlacesAction());
		// let places = await fetchTerminalPlacesApi(airport, terminal);
		// dispatch(fetchTerminalPlacesSuccessAction(places));
	}
}

export const selectLevelAction = createAction(actionTypes.SELECT_LEVEL);
export const selectLevel = selectLevelAction;

export const selectPlaceAction = createAction(actionTypes.SELECT_PLACE);
export function selectPlace(place) {
	return (dispatch) => {
		const needsLnglat = !place.lnglat;
		dispatch(selectPlaceAction({ place }));
		dispatch(setMapModeSetPlaceLocation(needsLnglat));
	}
}

export const unSelectPlaceAction = createAction(actionTypes.UNSELECT_PLACE);
export const unSelectPlace = unSelectPlaceAction;

export function selectPlaceByUuid(placeUuid) {
	return (dispatch, getState) => {
		const place = getState()
			.airports.selectedAirport.selectedTerminal.places
			.find(place => place.uuid === placeUuid);
		if (place)
			dispatch(selectPlaceAction({ place }));
	}
}

export const setMapModeSetPlaceLocationAction = createAction(actionTypes.SET_MAP_MODE_SET_PLACE_LOCATION);
export function setMapModeSetPlaceLocation(set) {
	return (dispatch, getState) => {
		if (set !== getState().map.modeSetCoordinates)
			dispatch(setMapModeSetPlaceLocationAction(set));
	}
}

export const setCoordinatesAction = createAction(actionTypes.SET_COORDINATES);
export const setCoordinates = setCoordinatesAction;

export const setSelectedPlaceLocationAction = createAction(actionTypes.SET_SELECTED_PLACE_LOCATION);
export const putPlaceLocationAction = createAction(actionTypes.PUT_SELECTED_PLACE_LOCATION);
export const putPlaceLocationSuccessAction = createAction(actionTypes.PUT_SELECTED_PLACE_LOCATION_SUCCESS);
export const setSelectedPlaceLocation = setSelectedPlaceLocationAction;
export function putSelectedPlaceLocation(lnglat) {
	return async (dispatch, getState) => {
		dispatch(setSelectedPlaceLocationAction({
			placeUuid: getState().places.selectedPlace.uuid,
			lnglat,
		}));
		// dispatch(putPlaceLocationAction());
		const selectedPlace = getState().places.selectedPlace;
		const selectedAirport = getState().airports.selectedAirport;
		// await putPlaceLocationApi(selectedAirport, selectedPlace, lnglat);
		// dispatch(putPlaceLocationSuccessAction());
	}
}

export const createPlaceAction = createAction(actionTypes.CREATE_PLACE);
export function createPlace(add) {
	return (dispatch) => {
		dispatch(createPlaceAction(add));
		dispatch(setMapModeSetPlaceLocation(true));
	}
}

export const setPlaceAction = createAction(actionTypes.SET_PLACE);
export function setPlace(placeUuid, metaPlace, zone, level, near) {
	return (dispatch) => {
		dispatch(setPlaceAction({
			placeUuid: placeUuid ? placeUuid : null,
			metaPlace,
			zone,
			level,
			near,
		}));
	}
}

export const putNewPlaceAction = createAction(actionTypes.PUT_NEW_PLACE);
export const putNewPlaceSuccessAction = createAction(actionTypes.PUT_NEW_PLACE_SUCCESS);
export function putNewPlace(metaplaceUuid, zone, level, near) {
	return async (dispatch, getState) => {
		const selectedAirport = getState().airports.selectedAirport;
		const lnglat = getState().placeEditor.place.lnglat;
		// dispatch(putNewPlaceAction());
		// const place = await createPlaceApi(selectedAirport, metaplaceUuid, lnglat, zone, level, near);
		// dispatch(putNewPlaceSuccessAction());
		// dispatch(appendNewPlace(place));
	}
}

export const appendNewPlaceAction = createAction(actionTypes.APPEND_NEW_PLACE);
export const appendNewPlace = appendNewPlaceAction;

export const putExistingPlaceAction = createAction(actionTypes.PUT_EXISTING_PLACE);
export function putExistingPlace(placeUuid, metaplaceUuid, zone, level, near) {
	return async (dispatch, getState) => {
		const selectedAirport = getState().airports.selectedAirport;
		const lnglat = getState().placeEditor.place.lnglat;
		// dispatch(putExistingPlaceAction());
		// const place = await updatePlaceApi(placeUuid, selectedAirport, metaplaceUuid, lnglat, zone, level, near);
		// dispatch(putExistingPlaceSuccessAction(place));
	}
}
export const putExistingPlaceSuccessAction = createAction(actionTypes.PUT_EXISTING_PLACE_SUCCESS);

export const deletePlaceAction = createAction(actionTypes.DELETE_PLACE);
export function deletePlace(placeUuid) {
	return async (dispatch, getState) => {
		const selectedAirport = getState().airports.selectedAirport;
		// dispatch(deletePlaceAction());
		// await deletePlaceApi(placeUuid, selectedAirport);
		// dispatch(deletePlaceSuccessAction(placeUuid));
	}
}
export const deletePlaceSuccessAction = createAction(actionTypes.DELETE_PLACE_SUCCESS);

export const selectPoiAction = createAction(actionTypes.SELECT_POI);
export function selectPoi(poiUuid) {
	return (dispatch, getState) => {
		const poi = getState().airports.selectedAirport.data.pois.find(({ uuid }) => uuid === poiUuid);
		const place = getState().places.places[poi.placeUuid];
		dispatch(selectPoiAction({ poi, place: { uuid: poi.placeUuid, ...place } }));
	}
}

export const showNavigation = createAction(actionTypes.SHOW_NAVIGATION);
export const showNavigationAction = showNavigation;

