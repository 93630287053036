import Airtable from 'airtable';
import _ from 'lodash';
import config from 'config';


Airtable.configure({ apiKey: config.AIRTABLE.API_KEY })

const PLACES_BASE_CONFIG = config.AIRTABLE.BASES.PLACES;
const AIRPORTS_BASE_CONFIG = config.AIRTABLE.BASES.AIRPORTS;
const placesBase = Airtable.base(PLACES_BASE_CONFIG.ID);

async function airtableGetRecords(baseId, tableName, viewName, filterByFormula = '') {
	if (!viewName)
		viewName = 'Bot view';
	const base = Airtable.base(baseId);
	const res = await new Promise((resolve, reject) => {
		const allRecords = [];
		base(tableName).select({
			view: viewName,
			filterByFormula,
		}).eachPage((records, fetchNextPage) => {
			allRecords.push(...records.map(record => record.fields));
			fetchNextPage();
		}, function done(err) {
			if (err) {
				console.error(err);
				reject(err);
			} else {
				resolve(allRecords);
			}
		});
	});
	return res;
}

export async function fetchAppConfig(airportIATA) {
	const res = await airtableGetRecords(AIRPORTS_BASE_CONFIG.ID, AIRPORTS_BASE_CONFIG.TABLES_NAMES.MAPBOX_URL)
	const mapboxUrl = res[0]['Url'];
	return {
		mapboxUrl,
	};
}

export async function fetchAirports() {
	const res = await airtableGetRecords(AIRPORTS_BASE_CONFIG.ID, AIRPORTS_BASE_CONFIG.TABLES_NAMES.AIRPORTS)
	return _.chain(res).map(fields => ({
		IATA: fields.IATA,
		lnglat: JSON.parse(fields.LngLat),
		name: fields.Name,
	})).mapKeys('IATA').value();
}

export async function fetchTerminals(airportIATA) {
	const res = await airtableGetRecords(AIRPORTS_BASE_CONFIG.ID, AIRPORTS_BASE_CONFIG.TABLES_NAMES.TERMINALS, null, `{Airport IATA}="${airportIATA.toUpperCase()}"`)
	return _.map(res, terminal => terminal.Name);
}

export async function fetchSecurityCheckpoints(airportIATA) {
	const res = await airtableGetRecords(AIRPORTS_BASE_CONFIG.ID, AIRPORTS_BASE_CONFIG.TABLES_NAMES.SECURITY_CHECKPOINTS, null, `{Airport IATA}="${airportIATA.toUpperCase()}"`)
	return _.map(res, poi => poi['Poi uuid']);
}
