import { createReducer } from '@reduxjs/toolkit'
import {
	fetchPlacesSuccessAction,
	fetchFieldsSuccessAction,
	selectPlaceAction,
	unSelectPlaceAction,
	setSelectedPlaceLocationAction,
	createPlaceAction,
	setPlaceAction,
} from 'redux/actions';
import { Place } from 'Models';

const initialState = {
	selectedPlace: null,
	place: null,
	metaPlaces: [],
	fields: {
		zones: [],
		levels: [],
		near: [],
	},
	newPlace: null,
};

const placesReducer = createReducer(initialState, {
	[fetchPlacesSuccessAction]: (state, action) => {
		state.places = action.payload;
	},
	[fetchFieldsSuccessAction]: (state, action) => {
		state.fields = action.payload;
	},
	[selectPlaceAction]: (state, action) => {
		const place = new Place(action.payload.place);
		state.selectedPlace = place.data;
		state.place = place.data;
		state.newPlace = null;
	},
	[unSelectPlaceAction]: (state) => {
		state.selectedPlace = initialState.selectedPlace;
		state.place = initialState.place;
	},
	[setSelectedPlaceLocationAction]: (state, action) => {
		state.selectedPlace.lnglat = action.payload.lnglat;
	},
	[createPlaceAction]: (state) => {
		state.selectedPlace = null;
	},
	[setPlaceAction]: (state, action) => {
		const lnglat = state.place ? state.place.lnglat : null;
		state.place = { lnglat, ...action.payload};
	},
});

export default placesReducer;
