import { createReducer } from '@reduxjs/toolkit'
import {
	setMapModeSetPlaceLocationAction,
	setCoordinatesAction,
	selectAirportAction,
	selectAirportTerminalAction,
	selectPoiAction,
	showNavigationAction
} from 'redux/actions';
import { Place } from 'Models';

const initialState = {
	modeSetCoordinates: false,
	placeCoordinates: null,
	selectedPoi: null,
	showNavigation: false,
};

const airportReducer = createReducer(initialState, {
	[setMapModeSetPlaceLocationAction]: (state, action) => {
		state.modeSetCoordinates = action.payload;
		state.placeCoordinates = initialState.placeCoordinates;
	},
	[setCoordinatesAction]: (state, action) => {
		state.modeSetCoordinates = false;
		state.placeCoordinates = action.payload;
	},
	[selectAirportTerminalAction]: (state) => {
		state.modeSetCoordinates = false;
	},
	[selectPoiAction]: (state, action) => {
		state.selectedPoi = action.payload;
	},
	[showNavigationAction]: (state, action) => {
		state.showNavigation = action.payload;
	},
})

export default airportReducer;
