import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	disabledContainer: {
		backgroundColor: '#f7f7f7',
	},
	title: {
		margin: '4px 0px',
		fontWeight: 'normal',
		fontSize: 18,
	},
	label: {
		margin: '5px 4px 3px',
	},
	coordinates: {
		margin: '5px 0',
	},
	actionButtons: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	}
});

export const reactSelectStyles = {
	menu: base => ({
		...base,
		zIndex: 4,
		position: 'absolute',
	})
}

