import React, {useState, useEffect, useRef} from 'react';

function useMessage() {
	const [message, setMessage] = useState(null);
	const [showMessage, setShowMessage] = useState(null);
	const timeout = useRef();

	function _setMessage(msg) {
		setMessage(msg);
		setShowMessage(true);
		if(timeout.current)
			clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			setShowMessage(false);
		}, 5000);
	}

	return [message, showMessage, _setMessage];
}

export function pushNotification() {

}

function Notification() {

	// const [message, setMessage] = useState(null);
	// const [showMessage, setShowMessage] = useState(null);
	const [message, showMessage, setMessage] = useMessage(null);
	

	return (
		<div id="messagebox" className={showMessage ? 'messageboxShow' : ''}>
			{message}
		</div>
	);
}

export default Notification;
