import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import sagas from './sagas';

// const preloadedState = {};
const sagaMiddleware = createSagaMiddleware();
const middlewares = [
	...getDefaultMiddleware(),
	sagaMiddleware,
];
const store = configureStore({
	reducer: rootReducer,
	middleware: middlewares,
	// preloadedState,
	devTools: process.env.NODE_ENV !== 'production',
})
sagaMiddleware.run(sagas);

if (process.env.NODE_ENV !== 'production' && module.hot) {
	module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
}

export default store;

