import{ useEffect, useRef } from 'react';

export function deepFreeze(o) {
	Object.freeze(o);

	Object.getOwnPropertyNames(o).forEach(function (prop) {
		if (o.hasOwnProperty(prop)
			&& o[prop] !== null
			&& (typeof o[prop] === "object" || typeof o[prop] === "function")
			&& !Object.isFrozen(o[prop])) {
			deepFreeze(o[prop]);
		}
	});

	return o;
};

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	function reset(){
		ref.current = null;
	}
	return [ref.current, reset];
}

