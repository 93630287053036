import config from 'config';
import { Place, Airport, MetaPlace } from 'Models';

// const spreadsheetId = '1eH9ARAHUPmG0pH7bx-ujcrEQVFAfzHOz5zNX3gJ68iU';
const spreadsheetId = null;

let gapi = window.gapi;

const RANGE_FIRST_ROW = 2;
const PLACE_UUID_COL = 'A';
const UUID_COL = 'K';
const COL_UUID = 'K';
const NAME_COL = 'E';
const LNGLAT_COL = 'N';
const GOOGLE_PLACEID_COL = 'O';
const FIRST_COL = 'A';
const LAST_COL = 'O';


export { UUID_COL, NAME_COL, LNGLAT_COL, GOOGLE_PLACEID_COL, LAST_COL };

export function getColNumber(colString, firstColString) {
	if (colString.length > 1)
		throw Error("Doesn't handle multiple letter columns");
	const offset = firstColString === undefined ? 0 : getColNumber(firstColString);
	// Not working after 'AZ'
	// return colString.split('').reduce((acc, letter, index) => acc + (letter.charCodeAt() - 65) + (26 * index), -offset);
	const letter = colString[0];
	return letter.charCodeAt() - 65 - offset;
}

export function getColString(colNumber) {
	if (colNumber > 25)
		throw Error("Doesn't handle multiple letter columns");
	return String.fromCharCode(colNumber + 65);
}

function getPlaceFromRow(row) {
	const COL_PLACE_UUID = 'A';
	const COL_UUID = 'K';
	const COL_FAMILY = 'C';
	const COL_CATEGORY = 'D';
	const COL_NAME = 'E';
	const COL_ZONE = 'G';
	const COL_LEVEL = 'H';
	const COL_NEAR = 'I';
	const COL_LOGO = 'L';
	const COL_ICON = 'M';
	const COL_LNGLAT = 'N';
	const COL_GOOGLE_PLACE_ID = 'O';
	const RANGE_FIRST_COL = 'A';
	const LAST_COL = 'O';

	const colN = (col) => getColNumber(col, RANGE_FIRST_COL);

	return new Place({
		uuid: row[colN(COL_UUID)],
		metaPlaceUuid: row[colN(COL_PLACE_UUID)],
		name: row[colN(COL_NAME)],
		family: row[colN(COL_FAMILY)],
		category: row[colN(COL_CATEGORY)],
		zone: row[colN(COL_ZONE)],
		level: row[colN(COL_LEVEL)],
		near: row[colN(COL_NEAR)],
		lnglat: row[colN(COL_LNGLAT)] ? JSON.parse(row[colN(COL_LNGLAT)]) : null,
		googlePlaceId: row[colN(COL_GOOGLE_PLACE_ID)],
	});
}

/**
 *  On load, called to load the auth2 library and API client library.
 */
export async function handleClientLoad(signedInCallback) {
	await new Promise((resolve) => {
		gapi.load('client:auth2', async () => {
			await initClient(signedInCallback);
			signedInCallback && signedInCallback(true);
			resolve();
		});
	});
}

/**
 *  Initializes the API client library and sets up sign-in state
 *  listeners.
 */
async function initClient(signedInCallback) {
	await new Promise((res, rej) => {
		gapi.client.init({
			apiKey: config.GAPI.API_KEY,
			clientId: config.GAPI.CLIENT_ID,
			discoveryDocs: config.GAPI.DISCOVERY_DOCS,
			scope: config.GAPI.SCOPES,
		}).then(async function () {
			// Listen for sign-in state changes.
			gapi.auth2.getAuthInstance().isSignedIn.listen(signedInCallback);
			if (!gapi.auth2.getAuthInstance().isSignedIn.get())
				await authenticate();
			res();

		}, function (error) {
			console.error(error);
			rej(error);
		});
	});
}

export async function authenticate() {
	if (gapi.auth2.getAuthInstance().isSignedIn.get())
		return;
	await new Promise((res, rej) => {
		gapi.auth2.getAuthInstance().signIn().then(() => {
			res();
		});
	});
}

export async function signout() {
	await new Promise((res, rej) => {
		gapi.auth2.getAuthInstance().signOut().then(() => {
			res();
		});
	});
}

export async function fetchAirports() {
	const colN = (col) => getColNumber(col, AIRPORTS_RANGE_FIRST_COL);

	const AIRPORTS_COL_IATA = 'A';
	const AIRPORTS_COL_SPREADSHEET_ID = 'B';
	const AIRPORTS_COL_NAME = 'C';
	const AIRPORTS_COL_LNGLAT = 'D';
	const AIRPORTS_COL_MAPBOX_SOURCE_TYPE = 'E';
	const AIRPORTS_COL_MAPBOX_URL = 'F';
	const AIRPORTS_COL_MAPBOX_LAYER = 'G';
	const AIRPORTS_RANGE_FIRST_COL = AIRPORTS_COL_IATA;
	const AIRPORTS_RANGE_LAST_COL = AIRPORTS_COL_MAPBOX_LAYER;

	const airports = await new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId: config.GAPI.GOOGLE_SHEET.AIRPORTS_SPREADSHEET_ID,
			range: `${config.GAPI.GOOGLE_SHEET.AIRPORTS_SHEET_NAME_AIRPORTS}!${AIRPORTS_RANGE_FIRST_COL}${RANGE_FIRST_ROW}:${AIRPORTS_RANGE_LAST_COL}`,
		}).then(function (response) {
			let airports = response.result.values.reduce((acc, row) => {
				acc[row[colN(AIRPORTS_COL_IATA)]] = new Airport({
					IATA: row[colN(AIRPORTS_COL_IATA)],
					spreadsheetId: row[colN(AIRPORTS_COL_SPREADSHEET_ID)],
					name: row[colN(AIRPORTS_COL_NAME)],
					lnglat: row[colN(AIRPORTS_COL_LNGLAT)] ? JSON.parse(row[colN(AIRPORTS_COL_LNGLAT)]) : null,
					mapboxSourceType: row[colN(AIRPORTS_COL_MAPBOX_SOURCE_TYPE)],
					mapboxUrl: row[colN(AIRPORTS_COL_MAPBOX_URL)],
					mapboxLayer: row[colN(AIRPORTS_COL_MAPBOX_LAYER)],
				}).data;
				return acc;
			}, {});
			res(airports);
		}, function (response) {
			console.log('Error: ' + response.result.error.message);
			rej(response);
		});
	});

	const TERMINALS_COL_IATA = 'A';
	const TERMINALS_COL_MAP_ID = 'B';
	const TERMINALS_COL_NAME = 'E';
	const TERMINALS_COL_MAPBOX_URL = 'D';
	const TERMINALS_COL_LEVEL = 'F';
	const TERMINALS_RANGE_FIRST_COL = TERMINALS_COL_IATA;
	const TERMINALS_RANGE_LAST_COL = TERMINALS_COL_LEVEL;

	await new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId: config.GAPI.GOOGLE_SHEET.AIRPORTS_SPREADSHEET_ID,
			range: `${config.GAPI.GOOGLE_SHEET.AIRPORTS_SHEET_NAME_TERMINALS}!${TERMINALS_RANGE_FIRST_COL}${RANGE_FIRST_ROW}:${TERMINALS_RANGE_LAST_COL}`,
		}).then(function (response) {
			response.result.values.map((row) => {
				if (!airports[row[colN(TERMINALS_COL_IATA)]])
					return null;
				if (!airports[row[colN(TERMINALS_COL_IATA)]].terminals)
					airports[row[colN(TERMINALS_COL_IATA)]].terminals = {};
				if (!airports[row[colN(TERMINALS_COL_IATA)]].terminals[row[colN(TERMINALS_COL_NAME)]])
					airports[row[colN(TERMINALS_COL_IATA)]].terminals[row[colN(TERMINALS_COL_NAME)]] = [];
				airports[row[colN(TERMINALS_COL_IATA)]].terminals[row[colN(TERMINALS_COL_NAME)]].push({
					id: row[colN(TERMINALS_COL_MAP_ID)],
					mapboxUrl: row[colN(TERMINALS_COL_MAPBOX_URL)],
					level: row[colN(TERMINALS_COL_LEVEL)],
				});
			}, {});
			res(airports);
		}, function (response) {
			console.log('Error: ' + response.result.error.message);
			rej(response);
		});
	});

	return airports;
}

export function fetchMetaPlaces() {
	const COL_FAMILY = 'A';
	const COL_CATEGORY = 'B';
	const COL_NAME = 'C';
	const COL_UUID = 'E';
	const COL_ORIGIN_AIRPORT = 'F';
	const RANGE_FIRST_COL = 'A';

	const colN = (col) => getColNumber(col, RANGE_FIRST_COL);

	return new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId: config.GAPI.GOOGLE_SHEET.PLACES_FIELDS_SPREADSHEET_ID,
			range: `${config.GAPI.GOOGLE_SHEET.PLACES_FIELDS_SHEET_NAME_PLACES}!${RANGE_FIRST_COL}${RANGE_FIRST_ROW}:${COL_ORIGIN_AIRPORT}`,
		}).then(function (response) {
			res(response.result.values
				.filter((row) =>
					row[colN(COL_FAMILY)] || row[colN(COL_CATEGORY)] || row[colN(COL_NAME)]
				).map(row => new MetaPlace({
					family: row[colN(COL_FAMILY)],
					category: row[colN(COL_CATEGORY)],
					name: row[colN(COL_NAME)],
					uuid: row[colN(COL_UUID)],
					originAirport: row[colN(COL_ORIGIN_AIRPORT)],
				}).data)
			);
		}, function (response) {
			console.log('Error: ' + response.result.error.message);
			rej(response);
		});
	});
}

export function fetchFields() {
	const COL_FAMILIES = 'A';
	const COL_CATEGORIES = 'B';
	const COL_ZONES = 'C';
	const COL_LEVELS = 'D';
	const COL_NEAR = 'E';
	const RANGE_FIRST_COL = 'A';

	const colN = (col) => getColNumber(col, RANGE_FIRST_COL);

	return new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId: config.GAPI.GOOGLE_SHEET.PLACES_FIELDS_SPREADSHEET_ID,
			range: `${config.GAPI.GOOGLE_SHEET.PLACES_FIELDS_SHEET_NAME_FIELDS}!${RANGE_FIRST_COL}${RANGE_FIRST_ROW}:${COL_NEAR}`,
		}).then(function (response) {
			const families = response.result.values.filter((row) => row[colN(COL_FAMILIES)]).map(row => row[colN(COL_FAMILIES)]);
			const categories = response.result.values.filter((row) => row[colN(COL_CATEGORIES)]).map(row => row[colN(COL_CATEGORIES)])
			const zones = response.result.values.filter((row) => row[colN(COL_ZONES)]).map(row => row[colN(COL_ZONES)])
			const levels = response.result.values
				.filter((row) => row[colN(COL_LEVELS)])
				.map(row => row[colN(COL_LEVELS)])
				.sort((a, b) => parseInt(b) - parseInt(a))
			const near = response.result.values.filter((row) => row[colN(COL_NEAR)]).map(row => row[colN(COL_NEAR)])
			res({
				families,
				categories,
				zones,
				levels,
				near,
			});
		}, function (response) {
			console.log('Error: ' + response.result.error.message);
			rej(response);
		});
	});
}

export function fetchTerminals(airportIATA) {
	const COL_IATA = 'A';
	const RANGE_FIRST_COL = 'A';
	const COL_TERMINAL_SHEET = 'E';

	const colN = (col) => getColNumber(col, RANGE_FIRST_COL);

	return new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId: config.GAPI.GOOGLE_SHEET.AIRPORTS_SPREADSHEET_ID,
			range: `${config.GAPI.GOOGLE_SHEET.AIRPORTS_SHEET_NAME_TERMINALS}!${RANGE_FIRST_COL}${RANGE_FIRST_ROW}:${COL_TERMINAL_SHEET}`,
		}).then(function (response) {
			res([...new Set(response.result.values
				.filter((row) => row[colN(COL_IATA)] === airportIATA && row[colN(COL_TERMINAL_SHEET)])
				.map(row => row[colN(COL_TERMINAL_SHEET)])
			)]);
		}, function (response) {
			console.log('Error: ' + response.result.error.message);
			rej(response);
		});
	});
}

export async function createPlace(spreadsheetId, sheet, metaplaceUuid, lnglat, zone, level, near) {
	const COL_META_PLACE_UUID = 'A';
	const COL_GOOGLE_PLACE_ID = 'O';

	const RANGE_FIRST_COL = COL_META_PLACE_UUID;
	const RANGE_LAST_COL = COL_GOOGLE_PLACE_ID;

	const colN = (col) => getColNumber(col, RANGE_FIRST_COL);

	let body = {
		values: [
			[
				metaplaceUuid,
				null, null, null, null, null,
				zone,
				level,
				near,
				null, null, null, null,
				lnglat ? JSON.stringify(lnglat) : null,
				null,
			],
		]
	};

	let rows = await new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId,
			range: `${sheet}!${RANGE_FIRST_COL}${RANGE_FIRST_ROW}:${RANGE_LAST_COL}`,
		}).then(function (response) {
			res(response.result.values);
		}, function (response) {
			console.error(response.result.error.message);
			rej();
		});
	});
	let availableRowIndex = null;
	rows.find((rows, index) => {
		if (!rows[colN(COL_META_PLACE_UUID)]) {
			availableRowIndex = index + RANGE_FIRST_ROW;
			return true;
		}
		return false;
	});

	const range = `${sheet}!${RANGE_FIRST_COL}${availableRowIndex}:${RANGE_LAST_COL}${availableRowIndex}`;
	const { updatedRange } = await updateRange(spreadsheetId, range, body);
	return getPlaceByRange(spreadsheetId, updatedRange);
}

export async function updatePlace(placeUuid, spreadsheetId, sheet, metaplaceUuid, lnglat, zone, level, near) {
	const COL_META_PLACE_UUID = 'A';
	const COL_GOOGLE_PLACE_ID = 'O';

	const RANGE_FIRST_COL = COL_META_PLACE_UUID;
	const RANGE_LAST_COL = COL_GOOGLE_PLACE_ID;

	let body = {
		values: [
			[
				metaplaceUuid,
				null, null, null, null, null,
				zone,
				level,
				near,
				null, null, null, null,
				lnglat ? JSON.stringify(lnglat) : null,
				null,
			],
		]
	};

	const rowToUpdate = await getPlaceRowByUuid(spreadsheetId, sheet, placeUuid);
	const range = `${sheet}!${RANGE_FIRST_COL}${rowToUpdate}:${RANGE_LAST_COL}${rowToUpdate}`;
	const { updatedRange } = await updateRange(spreadsheetId, range, body);
	return getPlaceByRange(spreadsheetId, updatedRange);
}

export async function deletePlace(placeUuid, spreadsheetId, sheet) {
	const COL_META_PLACE_UUID = 'A';
	const COL_GOOGLE_PLACE_ID = 'O';

	const RANGE_FIRST_COL = COL_META_PLACE_UUID;
	const RANGE_LAST_COL = COL_GOOGLE_PLACE_ID;

	let body = {
		values: [
			[
				'',
				null, null, null, null, null,
				'',
				'',
				'',
				null, null, null, null,
				'',
				null,
			],
		]
	};

	const rowToUpdate = await getPlaceRowByUuid(spreadsheetId, sheet, placeUuid);
	const range = `${sheet}!${RANGE_FIRST_COL}${rowToUpdate}:${RANGE_LAST_COL}${rowToUpdate}`;
	await updateRange(spreadsheetId, range, body);
	return true;
}

function updateRange(spreadsheetId, range, body) {
	return new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.update({
			spreadsheetId,
			range,
			valueInputOption: 'USER_ENTERED',
			resource: body
		}).then((response) => {
			let { result } = response;
			console.log(`${result.updatedCells} cells updated.`);
			res(result);
		});
	});
}

export const loadSheets = null;
// export async function loadSheets() {
// 	return new Promise((res, rej) => {
// 		gapi.client.sheets.spreadsheets.get({
// 			spreadsheetId,
// 		}).then(function (response) {
// 			res(response.result.sheets);
// 		}, function (response) {
// 			console.log('Error: ' + response.result.error.message);
// 			rej(response);
// 		});
// 	});
// }

export function fetchTerminalPlaces(airportSpreadsheetId, terminal) {
	const RANGE_FIRST_COL = 'A';
	const LAST_COL = 'O';

	const PLACE_UUID_COL = 'A';
	const NAME_COL = 'E';

	const colN = (col) => getColNumber(col, RANGE_FIRST_COL);

	return new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId: airportSpreadsheetId,
			range: `${terminal}!${RANGE_FIRST_COL}${RANGE_FIRST_ROW}:${LAST_COL}`,
		}).then(function (response) {
			res(response.result.values
				.filter(row => row[colN(PLACE_UUID_COL)])
				.sort((row1, row2) => {
					if (row1[colN(NAME_COL)] > row2[colN(NAME_COL)])
						return 1;
					if (row1[colN(NAME_COL)] < row2[colN(NAME_COL)])
						return -1;
					return 0;
				})
				.map(row => getPlaceFromRow(row).data)
			);
		}, function (response) {
			console.error(response.result.error.message);
			rej();
		});
	});
}

export const getPlaces = () => { };
// export async function getPlaces(sheet, firstRow) {
// 	return new Promise((res, rej) => {
// 		gapi.client.sheets.spreadsheets.values.get({
// 			spreadsheetId,
// 			range: `${sheet}!A${firstRow}:${LAST_COL}`,
// 		}).then(function (response) {
// 			res(response.result.values
// 				.filter(row => row[getColNumber(PLACE_UUID_COL)])
// 				.sort((row1, row2) => {
// 					if (row1[getColNumber(NAME_COL)] > row2[getColNumber(NAME_COL)])
// 						return 1;
// 					if (row1[getColNumber(NAME_COL)] < row2[getColNumber(NAME_COL)])
// 						return -1;
// 					return 0;
// 				})
// 			);
// 		}, function (response) {
// 			console.error(response.result.error.message);
// 			rej();
// 		});
// 	});
// }

export function getPlaceByRange(spreadsheetId, inputRange) {
	const range = inputRange.replace(/(.+!)[A-Z]+([0-9]+)(:[A-Z]+[0-9]+)?/u, `$1${FIRST_COL}$2:${LAST_COL}$2`);
	return new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId,
			range,
		}).then(function (response) {
			if (!response.result.values.length)
				rej();
			const row = response.result.values[0];
			res(getPlaceFromRow(row).data);
		}, function (response) {
			console.error(response.result.error.message);
			rej();
		});
	});
}

export async function getPlaceByUuid(spreadsheetId, sheet, placeUuid) {
	const COL_UUID = 'K';
	const RANGE_FIRST_COL = 'A';

	const colN = (col) => getColNumber(col, RANGE_FIRST_COL);

	let places = await new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId,
			range: `${sheet}!${RANGE_FIRST_COL}${RANGE_FIRST_ROW}:${LAST_COL}`,
		}).then(function (response) {
			res(response.result.values);
		}, function (response) {
			console.error(response.result.error.message);
			rej();
		});
	});
	return places.find(rows => rows[colN(COL_UUID)] === placeUuid);
}

export async function getPlaceRowByUuid(spreadsheetId, sheet, placeUuid) {
	const COL_UUID = 'K';
	const RANGE_FIRST_COL = 'A';

	const colN = (col) => getColNumber(col, RANGE_FIRST_COL);

	let places = await new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId,
			range: `${sheet}!${RANGE_FIRST_COL}${RANGE_FIRST_ROW}:${LAST_COL}`,
		}).then(function (response) {
			res(response.result.values);
		}, function (response) {
			console.error(response.result.error.message);
			rej();
		});
	});
	let index = RANGE_FIRST_ROW;
	places.find(rows => {
		if (rows[colN(COL_UUID)] === placeUuid)
			return true;
		index++;
		return false;
	});
	return index;
}

export const setPlaceLngLat = null;
export async function setPlaceLocation(spreadsheetId, sheet, placeUuid, lnglat) {
	const COL_UUID = 'K';
	const RANGE_FIRST_COL = COL_UUID;
	const colN = (col) => getColNumber(col, RANGE_FIRST_COL);

	var values = [
		[
			`[${lnglat[0]}, ${lnglat[1]}]`
		],
	];
	var body = {
		values: values
	};
	let range = await new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId,
			range: `${sheet}!${RANGE_FIRST_COL}${RANGE_FIRST_ROW}:${RANGE_FIRST_COL}`,
		}).then(function (response) {
			res(response.result.values);
		}, function (response) {
			console.error(response.result.error.message);
			rej();
		});
	});
	let rowIndex = null;
	range.find((rows, index) => {
		if (rows[colN(COL_UUID)] === placeUuid) {
			rowIndex = index + RANGE_FIRST_ROW;
			return true;
		}
		return false;
	});

	range = `${sheet}!${LNGLAT_COL}${rowIndex}:${LNGLAT_COL}${rowIndex}`;
	await new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.update({
			spreadsheetId,
			range,
			valueInputOption: 'RAW',
			resource: body
		}).then((response) => {
			var result = response.result;
			console.log(`${result.updatedCells} cells updated.`);
			res();
		});
	});
}

export async function setPlaceGooglePlaceId(sheet, placeUUID, googlePlace) {

	var values = [
		[
			googlePlace
		],
	];
	var body = {
		values: values
	};
	let range = await new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId,
			range: `${sheet}!${UUID_COL}${RANGE_FIRST_ROW}:${UUID_COL}`,
		}).then(function (response) {
			res(response.result.values);
		}, function (response) {
			console.error(response.result.error.message);
			rej();
		});
	});
	let rowIndex = null;
	range.find((rows, index) => {
		if (rows[0] === placeUUID) {
			rowIndex = index + RANGE_FIRST_ROW;
			return true;
		}
		return false;
	});

	range = `${sheet}!${GOOGLE_PLACEID_COL}${rowIndex}:${GOOGLE_PLACEID_COL}${rowIndex}`;
	await new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.update({
			spreadsheetId,
			range,
			valueInputOption: 'RAW',
			resource: body
		}).then((response) => {
			var result = response.result;
			console.log(`${result.updatedCells} cells updated.`);
			res();
		});
	});
}

export async function checkCellsLocation(sheet) {
	return new Promise((res, rej) => {
		gapi.client.sheets.spreadsheets.values.get({
			spreadsheetId,
			range: `${sheet}!R2:R3`,
		}).then(function (response) {
			let values = response.result.values;
			if (!values || !values[0])
				res(false);
			let matchesLnglat = values[0][0].match(/\$([A-Z]+)\$[0-9]+/);
			let matchesGooglePlaceId = values[1][0].match(/\$([A-Z]+)\$[0-9]+/);
			if (!matchesLnglat || !matchesGooglePlaceId)
				res(false);
			if (matchesLnglat[1] !== LNGLAT_COL || matchesGooglePlaceId[1] !== GOOGLE_PLACEID_COL)
				res(false);
			res(true);
		}, function (response) {
			console.error(response.result.error.message);
			rej();
		});
	});
}




