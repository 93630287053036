import { useState, useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as turf from '@turf/turf';
import { usePrevious } from 'utils';
import mapboxStyle from './mapbox-style';
import _, { cloneDeep } from 'lodash';
import chroma from 'chroma-js';
import {
	setCoordinates,
	selectPlaceByUuid,
	selectPoi,
	loadSecurityCheckpoints,
} from 'redux/actions';
import {
	setPlaceLngLat,
	checkCellsLocation,
	UUID_COL,
	NAME_COL,
	LNGLAT_COL,
	GOOGLE_PLACEID_COL,
	getColNumber,
	setPlaceGooglePlaceId,
} from 'google-sheet';

const mapboxStyleLayers = {};
mapboxStyle.forEach(layer => (mapboxStyleLayers[layer.id] = layer));

const glDrawLayerName = 'gl-draw-polygon-and-line-vertex-stroke-inactive.cold';

export function buildGeojsonFeature(place) {
	return {
		id: place.uuid,
		type: 'Feature',
		properties: {
			name: place.name,
			selected: false,
		},
		geometry: {
			type: 'Point',
			coordinates: place.lnglat
		}
	}
}

export function buildGeojsonFeatureGM(gmplace) {
	return {
		type: 'Feature',
		properties: {
			googlePlaceId: gmplace.place_id,
		},
		geometry: {
			type: 'Point',
			coordinates: [gmplace.geometry.location.lng(), gmplace.geometry.location.lat()]
		}
	}
}

function Map(props) {

	const {
		map,
		draw,
		fadeMap,
		fadeDelay,
		airports,
		selectedAirport,
		selectedTerminal,
		selectedLevel,
		places,
		categories,
		selectedPlace,
		newPlace,
		modeSetCoordinates,
		categoryColors,
		securityCheckpointsPoiUuids,
		mapboxUrl,
		showNavigation,
	} = props;
	const [prevSelectedPlace, resetPrevSelectedPlace] = usePrevious(selectedPlace);
	const [prevSelectedLevel, resetPrevSelectedLevel] = usePrevious(selectedTerminal.selectedLevel);
	// const [mapLayerIds, setMapLayerIds] = useState([]);
	const airportLayerIds = useRef([]);
	const terminalLayerIds = useRef([]);
	const [mapClickLngLat, setMapClickLngLat] = useState();
	const dispatch = useDispatch();

	const [spreadsheetTitle, setSpreadsheetTitle] = useState(null);
	const [currentPlace, setCurrentPlace] = useState(null);
	const [point, setPoint] = useState(null);
	const [placeSelected, setPlaceSelected] = useState(null);
	const [newPointLocation, setNewPointLocation] = useState(null);
	const [newPoint, setNewPoint] = useState(null);
	const [googlePlacesMarkers, setGooglePlacesMarkers] = useState(null);
	const [googlePlace, setGooglePlace] = useState(null);
	const [poiFeatureCollection, setPoiFeatureCollection] = useState(null);
	const [securityCheckpointsFeatureCollection, setSecurityCheckpointsFeatureCollection] = useState(null);

	const selectedAirportData = selectedAirport?.data?.airportData;
	const selectedAirportPois = selectedAirport?.data?.pois;
	const selectedAirportNav = selectedAirport?.data?.nav;

	useEffect(() => {
		if (!selectedAirport.IATA)
			return;

		const _airportLayerIds = [];

		const layerIds = [
			"airport-structure-polygon",
			"airport-structure-line",
			"airport-structure-border",
		];
		const sourceName = selectedAirport.IATA;
		const sourceLayerName = 'features';

		airportLayerIds.current.forEach(layerId => {
			if (map.getLayer(layerId))
				map.removeLayer(layerId);
		});

		if (!map.getSource(sourceName)) {
			map.addSource(sourceName, {
				type: 'vector',
				url: mapboxUrl,
			});
			_airportLayerIds.push(sourceName);
		}

		for (const layerId of layerIds) {
			const layer = cloneDeep(mapboxStyleLayers[layerId]);
			layer['source'] = sourceName;
			layer['source-layer'] = sourceLayerName;
			map.addLayer(layer, glDrawLayerName);
			_airportLayerIds.push(layerId);
		}

		airportLayerIds.current = _airportLayerIds;

	}, [map, selectedAirport.IATA, selectedAirport.mapboxLayer, mapboxUrl]);

	useEffect(() => {
		if (!selectedAirport.IATA)
			return;

		const _selectedLevel = selectedLevel || '0';
		const _terminalLayerIds = [];

		terminalLayerIds.current.forEach(layerId => {
			if (map.getLayer(layerId))
				map.removeLayer(layerId);
			if (map.getSource(layerId))
				map.removeSource(layerId);
		});

		const layerIds = [
			"airport-floor-polygon",
			"airport-floor-border",
			"airport-terrace-polygon",
			// "airport-terrace-border",
			"airport-other-polygon",
			"airport-other-border",
			"airport-place-polygon",
			"airport-place-border",
			// "airport-feature-landside-border",
			"airport-place-line",
		];
		const mapboxStyleLayers = {};
		mapboxStyle.forEach(layer => {
			if (layerIds.includes(layer.id))
				mapboxStyleLayers[layer.id] = layer;
		})
		const sourceName = selectedAirport.IATA;
		const sourceLayerName = 'features';

		for (const layerId of layerIds) {
			const layer = cloneDeep(mapboxStyleLayers[layerId]);
			layer['source'] = sourceName;
			layer['source-layer'] = sourceLayerName;
			layer['filter'].push([
				"match",
				["get", "level"],
				[parseInt(_selectedLevel)],
				true,
				false,
			]);
			if (layerId === 'airport-place-polygon') {
				for (const _index in layer['paint']['fill-color']) {
					const index = parseInt(_index);
					for (const category in categoryColors) {
						if (_.isEqual(layer['paint']['fill-color'][index], [category])) {
							layer['paint']['fill-color'][index + 1] = categoryColors[category];
						}
					}
				}
			}
			if (layerId === 'airport-place-border') {
				for (const _index in layer['paint']['line-color']) {
					const index = parseInt(_index);
					for (const category in categoryColors) {
						if (_.isEqual(layer['paint']['line-color'][index], [category])) {
							layer['paint']['line-color'][index + 1] = chroma(categoryColors[category]).darken(.5).hex();
						}
					}
				}
			}
			map.addLayer(layer, glDrawLayerName);
			_terminalLayerIds.push(layerId);
		}

		terminalLayerIds.current = _terminalLayerIds;

	}, [map, selectedAirport, selectedLevel, categoryColors]);

	useEffect(() => {
		if (!showNavigation) {
			map.getSource('airportNavNodesSource')?.setData(turf.featureCollection([]));
			map.getSource('airportNavEdgesSource')?.setData(turf.featureCollection([]));
			return;
		}

		if (!selectedAirport.IATA || !selectedAirport.data)
			return;
		if (!selectedAirportNav)
			return;

		const levels = _.flatMap(selectedAirport.data.airportData.structures, 'levels');

		const selectedLevelIds = _(levels).filter(level => selectedLevel === level.ordinal).map('id').value();

		const nodes = selectedAirportNav.nodes;
		const edges = selectedAirportNav.edges;

		const nodesFeatureCollection = {
			type: 'FeatureCollection',
			features: [],
		};

		_.transform(nodes, (acc, node, index) => {

			if (!selectedLevelIds.includes(node[2]))
				return;

			acc.push({
				type: 'Feature',
				id: index,
				properties: {
					nodeId: index,
				},
				geometry: {
					type: 'Point',
					coordinates: [node[0], node[1]],
				},
			});
		}, nodesFeatureCollection.features);

		const edgesFeatureCollection = {
			type: 'FeatureCollection',
			features: [],
		};

		_.transform(edges, (acc, edge, index) => {

			const nodeA = nodes[edge[0]];
			const nodeB = nodes[edge[1]];

			const oneWay = (edge[6] === true).toString();

			if (!selectedLevelIds.includes(nodeA[2]) || !selectedLevelIds.includes(nodeB[2]))
				return;

			const feature = turf.lineString([[nodeA[0], nodeA[1]], [nodeB[0], nodeB[1]]], { oneWay });

			acc.push(feature);
		}, edgesFeatureCollection.features);

		if (!map.getSource('airportNavNodesSource')) {
			map.addSource('airportNavNodesSource', {
				type: 'geojson',
				data: nodesFeatureCollection,
			});
		}

		if (!map.getLayer('airportNavNodesLayer')) {
			map.addLayer({
				id: 'airportNavNodesLayer',
				type: 'symbol',
				source: 'airportNavNodesSource',
				layout: {
					// 'circle-radius': 10,
					'icon-image': 'dot-10',
					'text-field': ['get', 'nodeId'],
					'text-size': 13,
					'icon-size': 1.2,
					'text-anchor': 'left',
					'text-offset': [1, 0],
					'text-ignore-placement': true,
					'icon-allow-overlap': true,
					'icon-ignore-placement': true,
				},
			});
		}

		if (!map.getSource('airportNavEdgesSource')) {
			map.addSource('airportNavEdgesSource', {
				type: 'geojson',
				data: edgesFeatureCollection,
			});
		}

		if (!map.getLayer('airportNavEdgesLineLayer')) {
			map.addLayer({
				id: 'airportNavEdgesLineLayer',
				type: 'line',
				source: 'airportNavEdgesSource',
				layout: {
				},
				paint: {
					'line-color': [
						'match',
						['get', 'oneWay'],
						'true',
						'red',
						'black',
					],
				},
			});
		}
		if (!map.getLayer('airportNavEdgesArrowLayer')) {
			map.addLayer({
				id: 'airportNavEdgesArrowLayer',
				type: 'symbol',
				source: 'airportNavEdgesSource',
				layout: {
					'icon-image': 'campsite-15',
					'icon-rotate': 90,
					'symbol-placement': 'line',
					'icon-allow-overlap': true,
					'icon-ignore-placement': true,
				},
				filter: ['==', ['get', 'oneWay'], 'true'],
			});
		}

		map.getSource('airportNavNodesSource').setData(nodesFeatureCollection);
		map.getSource('airportNavEdgesSource').setData(edgesFeatureCollection);
	}, [map, airports, selectedAirport, selectedLevel, selectedAirportNav, showNavigation]);

	useEffect(() => {
		if (!selectedAirport.IATA)
			return;
		dispatch(loadSecurityCheckpoints(selectedAirport.IATA));
	}, [dispatch, selectedAirport.IATA]);

	useEffect(() => {
		const poiFeatureCollection = {
			type: 'FeatureCollection',
			features: [],
		};
		const securityCheckpointsFeatureCollection = {
			type: 'FeatureCollection',
			features: [],
		};

		const securityCheckpointsPois = {};

		_.transform(selectedAirportPois, (acc, poi) => {
			// TODO: need a Place model exposing helper functions
			const place = places[poi.placeUuid];
			if (!place) {
				console.error('place not found', poi.placeUuid);
				return;
			}

			// if (!['Shops', 'Food', 'Lounges'].includes(place.family))
			// 	return;

			// if (place.category === 'Gate')
			// 	return;

			if (poi.level !== selectedLevel)
				return;

			if (securityCheckpointsPoiUuids.includes(poi.uuid)) {
				securityCheckpointsPois[poi.uuid] = poi;
				return;
			}

			let icon = '';
			let iconSize = .25;

			if (place.category && categories[place.category])
				icon = categories[place.category].icon.replace(/[^\w]/ug, '_');
			else
				icon = 'unknown_category';

			acc.push({
				type: 'Feature',
				id: poi.uuid,
				properties: {
					uuid: poi.uuid,
					text: place.placeName,
					icon,
					iconSize,
				},
				geometry: {
					type: 'Point',
					coordinates: poi.lngLat,
				},
			});
		}, poiFeatureCollection.features);

		_.transform(securityCheckpointsPoiUuids, (acc, poiUuid) => {
			const poi = securityCheckpointsPois[poiUuid];
			if (!poi)
				return;
			// TODO: need a Place model exposing helper functions
			const place = places[poi.placeUuid];
			if (!place) {
				console.error('place not found', poi.placeUuid);
				return;
			}

			if (poi.level !== selectedLevel)
				return;

			let icon = '';

			if (place.category && categories[place.category])
				icon = categories[place.category].icon.replace(/[^\w]/ug, '_');

			acc.push({
				type: 'Feature',
				id: poi.uuid,
				properties: {
					uuid: poi.uuid,
					text: place.placeName,
					icon,
					iconSize: .4,
				},
				geometry: {
					type: 'Point',
					coordinates: poi.lngLat,
				},
			});
		}, securityCheckpointsFeatureCollection.features);

		setPoiFeatureCollection(poiFeatureCollection);
		setSecurityCheckpointsFeatureCollection(securityCheckpointsFeatureCollection);
	}, [selectedAirportPois, selectedLevel, places, categories, securityCheckpointsPoiUuids]);

	useEffect(() => {
		if (_.isEmpty(poiFeatureCollection))
			return;

		if (!map.getSource('poisSource')) {
			map.addSource('poisSource', {
				type: 'geojson',
				data: poiFeatureCollection
			});
		}
		if (!map.getSource('securityCheckpointsSource')) {
			map.addSource('securityCheckpointsSource', {
				type: 'geojson',
				data: securityCheckpointsFeatureCollection,
			});
		}

		if (!map.getLayer('poisLayer')) {
			map.addLayer({
				id: 'poisLayer',
				type: 'symbol',
				source: 'poisSource',
				layout: {
					'icon-image': ['coalesce', ['image', ['get', 'icon']], ['image', 'unknown_category']],
					'icon-size': ['get', 'iconSize'],
				},
			});
			map.on('click', 'poisLayer', function (e) {
				dispatch(selectPoi(e.features[0]?.properties.uuid));
			});
		}

		if (!map.getLayer('securityCheckpointsLayer')) {
			map.addLayer({
				id: 'securityCheckpointsLayer',
				type: 'symbol',
				source: 'securityCheckpointsSource',
				layout: {
					'icon-image': ['get', 'icon'],
					'icon-size': ['get', 'iconSize'],
					'icon-allow-overlap': true,
				},
			});
			map.on('click', 'securityCheckpointsLayer', function (e) {
				dispatch(selectPoi(e.features[0]?.properties.uuid));
			});
		}

		map.getSource('poisSource').setData(poiFeatureCollection);
		map.getSource('securityCheckpointsSource').setData(securityCheckpointsFeatureCollection);

	}, [dispatch, map, poiFeatureCollection, securityCheckpointsFeatureCollection]);

	useEffect(() => {
		// for (let layer of airportLayers) {

		// 	// map.addLayer(layer, 'gl-draw-polygon-fill-inactive.cold');
		// 	map.addLayer(layer, glDrawLayerName);
		// }

		// map.addLayer({
		// 	'id': 'places',
		// 	'type': 'fill',
		// 	'source': {
		// 		'type': 'geojson',
		// 		'data': {}
		// 	},
		// 	'layout': {},
		// 	'paint': {
		// 		'fill-color': '#f08',
		// 		'fill-opacity': 0.4
		// 	}
		// });

		if (!map.getSource('google-places')) {
			map.addSource('google-places', {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: [],
				}
			});
		}
		if (!map.getLayer('google-places')) {
			map.addLayer({
				'id': 'google-places',
				'type': 'symbol',
				'source': 'google-places',
				"layout": {
					"icon-image": "location",
					"icon-size": 2
				}
			});
		}

		map.on('click', 'google-places', function (e) {
			setGooglePlace(e.features[0]);
		});
		map.on('mouseenter', 'google-places', function () {
			map.getCanvas().style.cursor = 'pointer';
		});
		map.on('mouseleave', 'google-places', function () {
			map.getCanvas().style.cursor = '';
		});
		map.on('click', function (e) {
			setMapClickLngLat([e.lngLat.lng, e.lngLat.lat])
		});
		map.on('click', 'airport-feature-polygon', function (e) {
			const features = e.features;
			for (const feature of features) {
				console.log(feature.properties);
			}
		});

		function updateArea(e) {
			let data = draw.getAll();
			let answer = document.getElementById('calculated-area');
			if (data.features.length > 0) {
				let area = turf.area(data);
				// restrict to area to 2 decimal points
				let rounded_area = Math.round(area * 100) / 100;
				answer.innerHTML = '<p><strong>' + rounded_area + '</strong></p><p>square meters</p>';
			} else {
				answer.innerHTML = '';
				if (e.type !== 'draw.delete') alert("Use the draw tools to draw a polygon!");
			}
		}

		map.on("draw.modechange", (e) => {
			console.log(e);
			// if (e.mode === "draw_polygon") {
			// 	draw.changeMode("draw_assisted_rectangle");
			// }
		});

		function onDraw(ev) {
			let evType = ev.type;
			const [feature] = ev.features;
			if (!feature)
				return;
			const { geometry: { coordinates, type } } = feature;
			if (type !== 'Point')
				return;
			if (evType === 'draw.create')
				setNewPoint(feature);
			else if (evType === 'draw.selectionchange') {
				setPlaceSelected(false);
				setPoint(feature);
			}
			else if (evType === 'draw.update')
				setNewPointLocation(feature);
		}

		const getFeatureFromEvent = (ev) => (ev.features.length ? ev.features[0] : null);

		// map.on('draw.update', onDraw);
		// map.on('draw.create', onDraw);
		// map.on('draw.selectionchange', onDraw);

		map.on('draw.update', (ev) => {
			const feature = getFeatureFromEvent(ev);
			if (feature)
				dispatch(setCoordinates(feature.geometry.coordinates));
		});
		map.on('draw.selectionchange', (ev) => {
			const feature = getFeatureFromEvent(ev);
			if (!feature)
				return;
			const placeUuid = feature.id;
			dispatch(selectPlaceByUuid(placeUuid));
		});

		// setDraw(draw);
		// setSheets(await loadSheets());
	}, [map, draw, dispatch]);

	useEffect(() => {
		if (modeSetCoordinates && mapClickLngLat) {
			setMapClickLngLat(null);
			dispatch(setCoordinates(mapClickLngLat));
		}
		else if (!modeSetCoordinates)
			setMapClickLngLat(null);
	}, [mapClickLngLat, modeSetCoordinates, dispatch]);

	useEffect(() => {
		if (!googlePlace || !currentPlace)
			return;
		(async function () {
			if (currentPlace && !currentPlace[getColNumber(GOOGLE_PLACEID_COL)]) {
				// setMessage(`Setting Google Place ID...`);
				let res = await checkCellsLocation(spreadsheetTitle);
				if (!res)
					return;
				await setPlaceGooglePlaceId(spreadsheetTitle, currentPlace[getColNumber(UUID_COL)], googlePlace.properties.googlePlaceId);
				let _places = [...places];
				_places = _places.map(place => {
					if (place[getColNumber(UUID_COL)] === currentPlace[getColNumber(UUID_COL)])
						place[getColNumber(GOOGLE_PLACEID_COL)] = googlePlace.properties.googlePlaceId;
					return place;
				});
				// setMessage(`Google Place ID set`);
				// setPlaces(_places);
				setGooglePlace(null);
				map.getSource('google-places').setData({ type: 'FeatureCollection', features: [] });
			}
		})();
	}, [places, map, googlePlace, currentPlace, spreadsheetTitle]);

	useEffect(() => {
		// if(!currentPlace || !newPoint)
		// 	return;
		if (!currentPlace || !placeSelected || (!point && !newPoint))
			return;

		map.getSource('google-places').setData({ type: 'FeatureCollection', features: [] });

		let _point = point || newPoint;

		(async function () {
			if (googlePlacesMarkers)
				googlePlacesMarkers.map(marker => marker.remove());

			if (currentPlace[getColNumber(GOOGLE_PLACEID_COL)])
				return;

			let div = document.createElement('div');
			let service = new window.google.maps.places.PlacesService(div);
			let request1 = {
				query: `${currentPlace[getColNumber(NAME_COL)]}`,
				locationBias: { lng: _point.geometry.coordinates[0], lat: _point.geometry.coordinates[1], radius: 30 },
				// locationBias: {center: {lng: 2.576889, lat: 49.005306}, radius: 8000},
				fields: ['name', 'geometry', 'place_id'],
			};
			let location = new window.google.maps.LatLng(_point.geometry.coordinates[1], _point.geometry.coordinates[0]);
			let request2 = {
				keyword: `${currentPlace[getColNumber(NAME_COL)]}`,
				// locationBias: {lng: 2.576889, lat: 49.005306},
				// location: {lng: newPoint.geometry.coordinates[0], lat: newPoint.geometry.coordinates[1]},
				location,
				radius: '20',
				// fields: ['name', 'geometry', 'place_id'],
			};
			// service.nearbySearch(request2, function (results, status) {
			service.findPlaceFromQuery(request1, function (results, status) {
				let placesMarkers = {
					"type": "FeatureCollection",
					"features": [],
				};
				if (status === window.google.maps.places.PlacesServiceStatus.OK) {
					console.log('results', results);
					for (var i = 0; i < results.length; i++) {
						console.log(results[i]);
						// placesMarkers.push(new mapboxgl.Marker()
						// 	.setLngLat([results[i].geometry.location.lng(), results[i].geometry.location.lat()])
						// 	.addTo(map)
						// );
						let gmplace = buildGeojsonFeatureGM(results[i]);
						placesMarkers.features.push(gmplace);
					}
					// setGooglePlacesMarkers(placesMarkers);
					map.getSource('google-places').setData(placesMarkers);
				}
			});
		})();
		// }, [currentPlace, newPoint, googlePlacesMarkers, draw, map]);
	}, [currentPlace, point, newPoint, placeSelected, googlePlacesMarkers, draw, map]);

	useEffect(() => {
		(async function () {
			if (newPoint) {
				if (currentPlace && !currentPlace[getColNumber(LNGLAT_COL)]) {
					// setMessage(`Placing marker...`);
					let res = await checkCellsLocation(spreadsheetTitle);
					if (!res)
						return;
					draw.setFeatureProperty(newPoint.id, 'name', currentPlace[getColNumber(NAME_COL)])
					await setPlaceLngLat(spreadsheetTitle, currentPlace[getColNumber(UUID_COL)], newPoint.geometry.coordinates[0], newPoint.geometry.coordinates[1]);
					// setMessage(`Marker placed`);
					let _places = [...places];
					_places = _places.map(place => {
						if (place[getColNumber(UUID_COL)] === currentPlace[getColNumber(UUID_COL)])
							place[getColNumber(LNGLAT_COL)] = `[${newPoint.geometry.coordinates[0]}, ${newPoint.geometry.coordinates[1]}]`;
						return place;
					});
					// setPlaces(_places);
					draw.delete(newPoint.id);
					let _newPlace = buildGeojsonFeature(currentPlace);
					draw.add(_newPlace);
					setPoint(newPoint);
					setNewPoint(null);
				}
			}
		})();
	}, [newPoint, places, currentPlace, spreadsheetTitle, draw]);

	useEffect(() => {
		if (!draw || !currentPlace || !newPointLocation || !places)
			return;

		(async function () {
			// setMessage(`Updating marker...`);
			await setPlaceLngLat(spreadsheetTitle, currentPlace[getColNumber(UUID_COL)], newPointLocation.geometry.coordinates[0], newPointLocation.geometry.coordinates[1]);
			// setMessage(`Marker updated`);
			setNewPointLocation(null);
		})();
	}, [places, currentPlace, newPointLocation, spreadsheetTitle, draw]);

	useEffect(() => {
		if (prevSelectedPlace && prevSelectedPlace.lnglat)
			if (draw.get(prevSelectedPlace.uuid))
				draw.setFeatureProperty(prevSelectedPlace.uuid, 'selected', false);
		if (selectedPlace && selectedPlace.lnglat && draw.get(selectedPlace.uuid)) {
			// if (!draw.get(selectedPlace.uuid))
			// draw.add(buildGeojsonFeature(selectedPlace))
			draw.setFeatureProperty(selectedPlace.uuid, 'selected', true);
			map.flyTo({
				center: selectedPlace.lnglat,
			});
		}
	}, [map, draw, prevSelectedPlace, selectedPlace]);

	useEffect(() => {
		resetPrevSelectedPlace();
	}, [resetPrevSelectedPlace, selectedTerminal]);

	// useEffect(() => {
	// 	let features = [];
	// 	for (let place of places) {
	// 		if (!place.lnglat)
	// 			continue;
	// 		if (place.level !== selectedLevel)
	// 			continue;
	// 		features.push(buildGeojsonFeature(place));
	// 	}
	// 	if (newPlace && newPlace.lnglat)
	// 		features.push(buildGeojsonFeature({
	// 			uuid: Math.random(),
	// 			...newPlace
	// 		}));
	// 	setImmediate(() => { // eslint-disable-line no-undef
	// 		draw.set({
	// 			type: 'FeatureCollection',
	// 			features,
	// 		});
	// 	});
	// }, [places, newPlace, draw, selectedLevel]);

	useEffect(() => {
		if (!selectedAirportData?.centerCoordinates)
			return;

		fadeMap(false);
		setTimeout(() => {
			map.jumpTo({
				center: [selectedAirportData.centerCoordinates[1], selectedAirportData.centerCoordinates[0]],
				zoom: 14,
			});
			fadeMap(true);
		}, fadeDelay);
	}, [map, selectedAirportData?.centerCoordinates, fadeMap, fadeDelay]);

	return null;
}

export default connect(state => ({
	mapboxUrl: state.appConfig.appConfig.mapboxUrl,
	airports: state.airports.airports,
	selectedAirport: state.airports.selectedAirport,
	places: state.places.places,
	categories: state.categories.categories,
	selectedTerminal: state.airports.selectedAirport.selectedTerminal,
	selectedLevel: state.airports.selectedAirport.selectedLevel,
	securityCheckpointsPoiUuids: state.airports.selectedAirport.securityCheckpointsPoiUuids,
	selectedPlace: state.places.selectedPlace,
	newPlace: state.placeEditor.place,
	modeSetCoordinates: state.map.modeSetCoordinates,
	categoryColors: state.colorEditor.colors,
	showNavigation: state.map.showNavigation,
}))(Map);
