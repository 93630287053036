import { createReducer } from '@reduxjs/toolkit'
import {
	fetchAirportsAction,
	fetchAirportsSuccessAction,
	selectAirportAction,
	fetchAirportAction,
	fetchAirportSuccessAction,
	selectAirportTerminalAction,
	fetchTerminalPlacesAction,
	fetchTerminalPlacesSuccessAction,
	selectLevelAction,
	setSelectedPlaceLocationAction,
	appendNewPlaceAction,
	putExistingPlaceSuccessAction,
	deletePlaceSuccessAction,
	fetchSecurityCheckpointsSuccessAction,
} from 'redux/actions';
import { Airport } from 'Models';

const initialState = {
	fetchingAirports: false,
	airports: {},
	selectedAirport: {
		fetchingAirport: false,
		fetchingTerminals: false,
		terminals: [],
		selectedLevel: null,
		selectedTerminal: {
			fetchingPlaces: false,
			name: '',
			places: [],
			selectedLevel: null,
		},
		securityCheckpointsPoiUuids: [],
	},
};

const airportReducer = createReducer(initialState, {
	[fetchAirportsAction]: (state) => {
		state.fetchingAirports = true;
	},
	[fetchAirportsSuccessAction]: (state, action) => {
		state.fetchingAirports = false;
		state.airports = action.payload;
	},
	[selectAirportAction]: (state, action) => {
		const IATA = action.payload;
		const airportData = state.airports[IATA];
		const airportModel = new Airport({ IATA, ...airportData });
		state.selectedAirport = { ...initialState.selectedAirport, ...airportModel.data };
	},
	// [fetchTerminalsAction]: (state, action) => {
	// 	state.selectedAirport.fetchingTerminals = true;
	// },
	// [fetchTerminalsSuccessAction]: (state, action) => {
	// 	state.selectedAirport.fetchingTerminals = false;
	// 	state.selectedAirport.terminals = action.payload;
	// },
	[selectAirportTerminalAction]: (state, action) => {
		state.selectedAirport.selectedTerminal = {
			...initialState.selectedAirport.selectedTerminal,
			name: action.payload
		};
	},
	[fetchAirportAction]: (state) => {
		state.selectedAirport.fetchingAirport = true;
	},
	[fetchAirportSuccessAction]: (state, action) => {
		state.selectedAirport.fetchingAirport = false;
		state.selectedAirport.data = action.payload;
	},
	[fetchTerminalPlacesAction]: (state) => {
		state.selectedAirport.selectedTerminal.fetchingPlaces = true;
	},
	[fetchTerminalPlacesSuccessAction]: (state, action) => {
		state.selectedAirport.selectedTerminal.fetchingPlaces = false;
		state.selectedAirport.selectedTerminal.places = action.payload;
	},
	[selectLevelAction]: (state, action) => {
		state.selectedAirport.selectedTerminal.selectedLevel = action.payload;
		state.selectedAirport.selectedLevel = action.payload;
	},
	[setSelectedPlaceLocationAction]: (state, action) => {
		state.selectedAirport.selectedTerminal.places.find(place => {
			if (place.uuid === action.payload.placeUuid) {
				place.lnglat = action.payload.lnglat;
				return true;
			}
			return false;
		});
	},
	[appendNewPlaceAction]: (state, action) => {
		state.selectedAirport.selectedTerminal.places.push(action.payload);
	},
	[putExistingPlaceSuccessAction]: (state, action) => {
		const places = state.selectedAirport.selectedTerminal.places;
		places.forEach((place, index) => {
			if (place.uuid === action.payload.uuid)
				places[index] = action.payload;
		});
	},
	[deletePlaceSuccessAction]: (state, action) => {
		let places = state.selectedAirport.selectedTerminal.places;
		places = places.filter(place => place.uuid !== action.payload);
		state.selectedAirport.selectedTerminal.places = places;
	},
	[fetchSecurityCheckpointsSuccessAction]: (state, action) => {
		state.selectedAirport.securityCheckpointsPoiUuids = action.payload;
	},
})

export default airportReducer;
