import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
	container: {
		position: 'absolute',
		top: 0,
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
	},
	terminalName: {
		height: 60,
		borderRadius: 30,
		marginTop: 20,
		padding: '0px 20px',
		background: '#006cb0',
		color: 'white',
		fontWeight: '300',
		fontSize: 40,
		alignSelf: 'center',
	},
	blinkTerminalName: {
		animation: 'blinkSpreadSheetTitle .3s step- start infinite',
	}
});
