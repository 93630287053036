import { createReducer } from '@reduxjs/toolkit'
import {
	fetchAppConfigSuccessAction,
} from 'redux/actions';

const initialState = {
	appConfig: {},
};

const appConfigReducer = createReducer(initialState, {
	[fetchAppConfigSuccessAction]: (state, action) => {
		state.appConfig = action.payload;
	},
})

export default appConfigReducer;
