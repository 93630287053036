import { all, call, spawn } from 'redux-saga/effects';
import { default as airportSaga } from './airportSaga';
import { default as placeSaga } from './placeSaga';

export default function* rootSaga() {
	const sagas = [
		airportSaga,
		placeSaga,
	];

	yield all(sagas.map(saga =>
		spawn(function* () {
			while (true) {
				try {
					yield call(saga);
					break;
				} catch (e) {
					console.error(e);
				}
			}
		}))
	);
}
