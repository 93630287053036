import { createAction } from '@reduxjs/toolkit';
import actionTypes from 'redux/actionTypes';
import {
	PLACE_EDIT_ADD,
	PLACE_EDIT_UPDATE,
} from 'redux/modeTypes';
import {
} from 'Api';

export const setPlaceAction = createAction(actionTypes['PLACE_EDITOR.SET_PLACE']);
export function setPlace(placeUuid, metaPlace, zone, level, near) {
	return (dispatch, getState) => {
		let lnglat;
		const placeEditorMode = getState().placeEditor.mode;

		if (placeEditorMode === PLACE_EDIT_UPDATE)
			lnglat = getState().placeEditor.place.lnglat;
		else if (placeEditorMode === PLACE_EDIT_ADD)
			lnglat = getState().map.placeCoordinates;
		
		dispatch(setPlaceAction({
			placeUuid: placeUuid ? placeUuid : null,
			metaPlace,
			zone,
			level,
			near,
			lnglat,
		}));
	}
}

export const setPlaceCoordinatesAction = createAction(actionTypes["PLACE_EDITOR.SET_PLACE_COORDINATES"]);
export const setPlaceCoordinates = setPlaceCoordinatesAction;

