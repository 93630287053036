import { put, call, takeEvery, select } from 'redux-saga/effects'
import {
	setCoordinatesAction,
	putSelectedPlaceLocation,
} from 'redux/actions';
import {
	setPlaceCoordinates,
} from 'redux/placeEditorActions';
import {
	PLACE_EDIT_ADD,
	PLACE_EDIT_UPDATE,
} from 'redux/modeTypes';

function* putPlace(action) {
	let { mode } = yield select(state => state.placeEditor);

	if (mode === PLACE_EDIT_UPDATE)
		yield put(putSelectedPlaceLocation(action.payload));
	else if (mode === PLACE_EDIT_ADD)
		yield put(setPlaceCoordinates(action.payload));
}

export default function* watchPlaces() {
	yield takeEvery(setCoordinatesAction, putPlace);
}


