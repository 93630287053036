import axios from 'axios';
import config from 'config';


const flyterCdn = axios.create({
	baseURL: config.FLYTER.CDN_BASE_URI
});

export async function fetchAirports() {
	const res = await flyterCdn.get('/airport-data/airports.json', { params: { v: Math.random() } });
	return res.data;
}

export async function fetchAirport(IATA) {
	const airportDataUri = `/airport-data/airport/${IATA}.json`;
	const navUri = `/airport-data/nav/${IATA}.json`;
	const poisUri = `/airport-data/pois/${IATA}.json`;
	const randomVersionParams = { params: { v: Math.random() } };
	const res = await Promise.all([
		flyterCdn.get(airportDataUri, randomVersionParams),
		flyterCdn.get(navUri, randomVersionParams),
		flyterCdn.get(poisUri, randomVersionParams),
	]);
	return {
		airportData: res[0].data,
		nav: res[1].data,
		pois: res[2].data,
	};
}

export async function fetchPlaces() {
	const res = await flyterCdn.get('/airport-data/places.json', { params: { v: Math.random() } });
	return res.data;
}

export async function fetchCategories() {
	const res = await flyterCdn.get('/airport-data/categories.json', { params: { v: Math.random() } });
	return res.data;
}
