import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import styles, { reactSelectStyles } from './styles';
import Button from '@material-ui/core/Button';
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';
import {
	createPlace,
	putNewPlace,
	putExistingPlace,
	deletePlace,
} from 'redux/actions';
import {
	setPlace,
} from 'redux/placeEditorActions';
import {
	PLACE_EDIT_ADD,
	PLACE_EDIT_UPDATE,
} from 'redux/modeTypes';

function PlaceEditor(props) {

	const {
		selectedPlace,
		placeEditorMode,
		placeCoordinates,
		metaPlaces,
		fields,
		selectedTerminal,
		selectedLevel,
	} = props;
	const [placeUuid, setPlaceUuid] = useState();
	const [metaPlace, setMetaplace] = useState();
	const [zone, setZone] = useState();
	const [level, setLevel] = useState();
	const [near, setNear] = useState();
	const [coordinates, setCoordinates] = useState();
	const [submitEnabled, setSubmitEnabled] = useState(false);

	const dispatch = useDispatch();

	const isDisabled = (placeEditorMode !== PLACE_EDIT_ADD && placeEditorMode !== PLACE_EDIT_UPDATE);

	useEffect(() => {
		const getMetaPlaceByUuid = (metaPlaceUuid) => metaPlaces.find(metaPlace => metaPlace.uuid === metaPlaceUuid);
		if (selectedPlace && placeEditorMode === PLACE_EDIT_UPDATE) {
			setPlaceUuid(selectedPlace.uuid);
			setMetaplace(getMetaPlaceByUuid(selectedPlace.metaPlaceUuid));
			setZone(selectedPlace.zone);
			setLevel(selectedPlace.level);
			setNear(selectedPlace.near);
			setCoordinates(selectedPlace.lnglat);
		}
		else if (placeEditorMode === PLACE_EDIT_ADD) {
			setMetaplace(null);
			setZone(null);
			setLevel(null);
			setNear(null);
		}
	}, [metaPlaces, selectedPlace, placeEditorMode]);

	useEffect(() => {
		if (selectedPlace && placeEditorMode === PLACE_EDIT_UPDATE) {
			dispatch(setPlace(placeUuid, metaPlace, zone, level, near));
		}
	}, [dispatch, placeEditorMode, selectedPlace, placeUuid, metaPlace, zone, level, near]);

	useEffect(() => {
		if (placeEditorMode === PLACE_EDIT_ADD) {
			setPlaceUuid(null);
			setLevel(selectedLevel);
		}
	}, [dispatch, placeEditorMode, selectedPlace, selectedLevel]);

	useEffect(() => {
		if (placeEditorMode === PLACE_EDIT_ADD) {
			dispatch(setPlace(null, metaPlace, zone, level, near));
		}
	}, [dispatch, placeEditorMode, selectedPlace, metaPlace, zone, level, near]);

	useEffect(() => {
		setSubmitEnabled(Boolean(metaPlace));
	}, [metaPlace]);

	useEffect(() => {
		setCoordinates(placeCoordinates);
	}, [placeCoordinates]);

	useEffect(() => {
		if (placeEditorMode === PLACE_EDIT_ADD)
			setCoordinates(null);
	}, [placeEditorMode]);

	const onCreatePlaceClick = () => dispatch(createPlace(true));
	const onClickAdd = () => !isDisabled && submitEnabled && dispatch(putNewPlace(metaPlace.uuid, zone, level, near));
	const onClickUpdate = () => !isDisabled && submitEnabled && dispatch(putExistingPlace(placeUuid, metaPlace.uuid, zone, level, near));
	const onClickDelete = () => !isDisabled && dispatch(deletePlace(placeUuid));
	const getMetaPlaceString = (metaPlace) => `${metaPlace.name} (${metaPlace.category}) [${metaPlace.family}]`;

	if (!selectedTerminal || !selectedTerminal.name)
		return null;

	return (
		<div className={css(styles.container, isDisabled ? styles.disabledContainer : null)}>
			<div className={css(styles.actionButtons)}>
				<Button color="primary" disableElevation onClick={onCreatePlaceClick}>
					Ajouter une place
				</Button>
			</div>
			<label className={css(styles.label)}>Place</label>
			<Select
				menuPlacement={'auto'}
				onChange={({ value }) => setMetaplace(value)}
				options={[...metaPlaces].sort((a, b) => a.name.localeCompare(b.name)).map(metaPlace => ({
					value: metaPlace,
					label: getMetaPlaceString(metaPlace),
				}))}
				value={metaPlace && {
					value: metaPlace,
					label: getMetaPlaceString(metaPlace),
				}
				}
				isDisabled={isDisabled}
			/>
			<label className={css(styles.label)}>Zone</label>
			<Select
				menuPlacement={'auto'}
				onChange={({ value }) => setZone(value)}
				options={fields.zones.map(zone => ({
					value: zone,
					label: zone,
				}))}
				isDisabled={isDisabled}
				value={zone && {
					value: zone,
					label: zone,
				}}
			/>
			<label className={css(styles.label)}>Level</label>
			<Select
				menuPlacement={'auto'}
				onChange={({ value }) => {
					if (placeEditorMode === PLACE_EDIT_UPDATE)
						setLevel(value)
					else if (placeEditorMode === PLACE_EDIT_ADD) {
						if (level !== selectedLevel || selectedLevel === null)
							setLevel(value)
						else if (level === selectedLevel
							&& selectedLevel !== null
							// eslint-disable-next-line no-alert
							&& window.confirm("Changer le niveau?"))
							setLevel(value)
					}
				}}
				options={fields.levels.map(level => ({
					value: level,
					label: level,
				}))}
				isDisabled={isDisabled}
				value={level && {
					value: level,
					label: level,
				}}
			/>
			<label className={css(styles.label)}>Near</label>
			<Select
				menuPlacement={'auto'}
				onChange={({ value }) => setNear(value)}
				options={fields.near.map(near => ({
					value: near,
					label: near,
				}))}
				isDisabled={isDisabled}
				value={near && {
					value: near,
					label: near,
				}}
			/>
			<label className={css(styles.label)}>Coords</label>
			<div className={css(styles.coordinates)}>
				{coordinates ?
					<div>{`lng : ${coordinates[0]}, lat : ${coordinates[1]}`}</div>
					:
					<div>&nbsp;</div>
				}
			</div>
			<div className={css(styles.actionButtons)}>
				{placeEditorMode === PLACE_EDIT_ADD &&
					<Button variant="contained" color="primary" disableElevation disabled={isDisabled || !submitEnabled} onClick={onClickAdd}>
						Ajouter
				</Button>
				}
				{placeEditorMode === PLACE_EDIT_UPDATE &&
					<>
						<Button variant="contained" color="primary" disableElevation disabled={isDisabled || !submitEnabled} onClick={onClickUpdate}>
							Mettre à jour
						</Button>
						&nbsp;
						<Button variant="outlined" color="secondary" disableElevation disabled={isDisabled} onClick={() => {
							// eslint-disable-next-line no-alert
							if (window.confirm('Supprimer?'))
								onClickDelete()
						}}>
							Supprimer
						</Button>
					</>
				}
			</div>
		</div>
	);
}

PlaceEditor.propTypes = {
	selectedPlace: PropTypes.object,
	placeCoordinates: PropTypes.array,
	metaPlaces: PropTypes.array,
	fields: PropTypes.exact({
		zones: PropTypes.array,
		levels: PropTypes.array,
		near: PropTypes.array,
		families: PropTypes.array,
		categories: PropTypes.array,
	}),
	selectedTerminal: PropTypes.object,
	selectedLevel: PropTypes.string,
	placeEditorMode: PropTypes.string,
}

export default connect(state => ({
	selectedPlace: state.places.selectedPlace,
	placeCoordinates: state.map.placeCoordinates,
	metaPlaces: state.places.metaPlaces,
	fields: state.places.fields,
	selectedTerminal: state.airports.selectedAirport.selectedTerminal,
	selectedLevel: state.airports.selectedAirport.selectedTerminal.selectedLevel,
	placeEditorMode: state.placeEditor.mode,
}))(PlaceEditor);
