import { StyleSheet } from 'aphrodite';

export default fadeDelay => StyleSheet.create({
	map: {
		width: '100%',
		height: '100%',
	},
	fadeIn: {
		opacity: 1,
		transition: `opacity ${fadeDelay / 1000}s`,
	},
	fadeOut: {
		opacity: 0,
		transition: `opacity ${fadeDelay / 1000}s`,
	},
});

export const mapboxDrawStyles = [
	{
		'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
		'type': 'circle',
		'filter': ['all',
			['==', 'meta', 'vertex'],
			['==', '$type', 'Point'],
			['!=', 'mode', 'static']
		],
		'paint': {
			'circle-radius': 5,
			'circle-color': '#fff'
		}
	},
	{
		'id': 'gl-draw-polygon-and-line-vertex-inactive',
		'type': 'circle',
		'filter': ['all',
			['==', 'meta', 'vertex'],
			['==', '$type', 'Point'],
			['!=', 'mode', 'static']
		],
		'paint': {
			'circle-radius': 3,
			'circle-color': '#fbb03b'
		}
	},
	{
		'id': 'gl-draw-point-point-stroke-inactive',
		'type': 'circle',
		'filter': ['all',
			['==', 'active', 'false'],
			['==', '$type', 'Point'],
			['==', 'meta', 'feature'],
			['!=', 'mode', 'static']
		],
		'paint': {
			'circle-radius': 5,
			'circle-opacity': 1,
			'circle-color': '#fff'
		}
	},
	{
		'id': 'gl-draw-point-inactive',
		'type': 'circle',
		'filter': ['all',
			['==', 'active', 'false'],
			['==', '$type', 'Point'],
			['==', 'meta', 'feature'],
			['!=', 'mode', 'static']
		],
		'paint': {
			'circle-radius': 3,
			'circle-color': '#3bb2d0'
		}
	},
	{
		'id': 'gl-draw-point-stroke-active',
		'type': 'circle',
		'filter': ['all',
			['==', '$type', 'Point'],
			['==', 'active', 'true'],
			['!=', 'meta', 'midpoint']
		],
		'paint': {
			'circle-radius': 7,
			'circle-color': '#fff'
		}
	},
	{
		'id': 'gl-draw-point-active',
		'type': 'circle',
		'filter': ['all',
			['==', '$type', 'Point'],
			['!=', 'meta', 'midpoint'],
			['==', 'active', 'true']],
		'paint': {
			'circle-radius': 5,
			'circle-color': '#fbb03b'
		}
	},
	{
		'id': 'gl-draw-point-static',
		'type': 'circle',
		'filter': ['all',
			['==', 'mode', 'static'],
			['==', '$type', 'Point']
		],
		'paint': {
			'circle-radius': 5,
			'circle-color': '#404040'
		}
	},
	{
		'id': 'gl-draw-point-stroke-inactive-selected',
		'type': 'circle',
		'filter': ['all',
			['==', '$type', 'Point'],
			['!=', 'meta', 'midpoint'],
			['==', 'active', 'false'],
			['==', 'user_selected', true],
		],
		'paint': {
			'circle-radius': 7,
			'circle-color': '#2255BB',
		}
	},
	{
		'id': 'gl-draw-point-stroke-active-selected',
		'type': 'circle',
		'filter': ['all',
			['==', '$type', 'Point'],
			['!=', 'meta', 'midpoint'],
			['==', 'active', 'true'],
			['==', 'user_selected', true],
		],
		'paint': {
			'circle-radius': 7,
			'circle-color': '#FF0000'
		}
	},
	{
		'id': 'gl-draw-point-selected',
		'type': 'circle',
		'filter': ['all',
			['==', '$type', 'Point'],
			['==', 'user_selected', true]
		],
		'paint': {
			'circle-radius': 5.5,
			'circle-color': '#fff'
		}
	},
];
