import { createReducer } from '@reduxjs/toolkit'
import {
	selectPlaceAction,
	unSelectPlaceAction,
	setSelectedPlaceLocationAction,
	createPlaceAction,
	putNewPlaceSuccessAction,
	deletePlaceSuccessAction,
} from 'redux/actions';
import {
	setPlaceCoordinatesAction,
	setPlaceAction,
} from 'redux/placeEditorActions';
import {
	PLACE_EDIT_NONE,
	PLACE_EDIT_ADD,
	PLACE_EDIT_UPDATE,
} from 'redux/modeTypes';
import { Place } from 'Models';

const initialState = {
	place: null,
	mode: PLACE_EDIT_NONE,
};

const placeEditorReducer = createReducer(initialState, {
	[selectPlaceAction]: (state, action) => {
		const place = new Place(action.payload.place);
		state.place = place.data;
		state.mode = PLACE_EDIT_UPDATE;
	},
	[unSelectPlaceAction]: (state) => {
		state.place = initialState.place;
		state.mode = initialState.mode;
	},
	[setSelectedPlaceLocationAction]: (state, action) => {
		state.place.lnglat = action.payload.lnglat;
	},
	[createPlaceAction]: (state) => {
		state.mode = PLACE_EDIT_ADD;
		state.place = {};
	},
	[setPlaceAction]: (state, action) => {
		const lnglat = state.place ? state.place.lnglat : null;
		state.place = { lnglat, ...action.payload};
	},
	[putNewPlaceSuccessAction]: (state) => {
		state.mode = PLACE_EDIT_NONE;
		state.place = initialState.place;
	},
	[setPlaceCoordinatesAction]: (state, action) => {
		state.place.lnglat = action.payload;
	},
	[deletePlaceSuccessAction]: (state) => {
		state.mode = PLACE_EDIT_NONE;
		state.place = initialState.place;
	},
});

export default placeEditorReducer;
