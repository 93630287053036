import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
	loadAppConfig,
	loadAirports,
	loadPlaces,
	loadFields,
	loadCategories,
} from 'redux/actions';
// import { handleClientLoad } from 'google-sheet';
import PlaceList from 'Components/PlaceList';
import HeaderInfo from 'Components/HeaderInfo';
import Map from 'Components/Map';
import ColorEditor from 'Components/ColorEditor';
// import { gapiSetLoggedInStatus } from './redux/actions';


const theme = createMuiTheme({
	palette: {
		primary: { main: '#0069ab' },
	},
});

function App() {

	const dispatch = useDispatch();

	useEffect(() => {
		(async function () {
			// await handleClientLoad((signed) => {
			// 	dispatch(gapiSetLoggedInStatus(signed));
			// });
			dispatch(loadAppConfig());
			dispatch(loadAirports());
			dispatch(loadPlaces());
			dispatch(loadCategories());
			dispatch(loadFields());
		})();
	}, [dispatch]);

	return (
		<ThemeProvider theme={theme}>
			<div id="App" className={css(styles.App)}>
				<Map />
				<HeaderInfo />
				<PlaceList />
				<ColorEditor />
			</div>
		</ThemeProvider>
	);
}

const styles = StyleSheet.create({
	App: {
		justifyContent: 'center',
		display: 'flex',
		alignItems: 'center',
	},
});

export default App;
