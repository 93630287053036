import React, { useState, useEffect } from 'react';
import { css } from 'aphrodite/no-important';
import { connect, useDispatch } from 'react-redux';
import { SketchPicker } from 'react-color';
import chroma from 'chroma-js';
import styles from './styles';
import {
	setColors,
} from 'redux/colorEditorActions';
import colorPalette from 'icons/color-palette.png';


const categories = [
	"food",
	"service",
	"shop",
	"lounge",
	"toilet",
	"health",
	"facility",
];

function ColorEditor(props) {

	const {
	} = props;

	const [openColorPicker, setOpenColorPicker] = useState(false);
	const [categoryColorMapping, setCategoryColorMapping] = useState({});
	const [selectedCategory, setSelectedCategory] = useState(categories[0]);
	const [showColorPicker, setShowColorPicker] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		setTimeout(() => {
			setShowColorPicker(true);
		}, 0);
	}, [])

	function handleChangeComplete(color) {
		const _categoryColorMapping = {
			...categoryColorMapping,
			[selectedCategory]: color.hex,
		}
		setCategoryColorMapping(_categoryColorMapping);
		dispatch(setColors(_categoryColorMapping));
	}

	return (
		<div className={css(styles.container)}>
			<div onClick={() => setOpenColorPicker(!openColorPicker)}>
				<img className={css(styles.colorPaletteIcon)} src={colorPalette} />
			</div>
			{openColorPicker && <>
				<ul className={css(styles.categoryList)}>
					{categories.map((category) => {
						return (
							<li
								className={css(styles.category)}
								key={category}
								onClick={() => {
									setSelectedCategory(category);
								}}
							>
								{category === selectedCategory &&
									<span className={css(styles.categorySelectedMarker)}>-&gt;</span>
								}
								{category}
								&nbsp;&nbsp;
								<span style={{
									backgroundColor: categoryColorMapping[category],
									color: categoryColorMapping[category] && chroma(categoryColorMapping[category]).luminance() > .5 ? 'black' : 'white'
								}}>{categoryColorMapping[category]}</span>
							</li>
						);
					})}
				</ul>
				{showColorPicker &&
					<SketchPicker
						color={categoryColorMapping[selectedCategory]}
						onChangeComplete={handleChangeComplete}
						disableAlpha={true}
					/>
				}
			</>}
		</div>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
	}
}

export default connect(mapStateToProps)(ColorEditor);
