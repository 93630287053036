import { StyleSheet } from 'aphrodite/no-important';

export default StyleSheet.create({
	container: {
		position: 'fixed',
		top: '20px',
		left: '20px',
		overflow: 'auto',
		// height: 'calc(100vh - 60px)',
		width: '400px',
		borderRadius: '15px',
		backgroundColor: 'white',
		boxShadow: '0px 14px 41px -21px #0000004a',
	},
	inner: {
		padding: '10px',
		display: 'flex',
		flexDirection: 'column',
		// position: 'absolute',
		top: '0',
		bottom: '0',
		left: '0',
		right: '0',
		overflow: 'hidden',
	},
	selectBox: {
		marginTop: 5,
		marginBottom: 5,
	},
	airportSelect: {
	},
	terminalLevelsContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	terminalSelect: {
		flex: 1,
		flexDirection: 'column',
		marginRight: 4,
	},
	levelSelect: {
		flex: 1,
		flexDirection: 'column',
		marginLeft: 4,
	},
	innerForm: {
		display: 'flex',
		flexDirection: 'column'
	},
	sectionTitle: {
		fontSize: 17,
		margin: '0 0 3px'
	},
	terminals: {
		display: 'flex',
		flexDirection: 'row'
	},
	terminalsSelect: {
		flex: 1,
	},
	placelistContainer: {
		overflow: 'auto',
		margin: '0 -10px',
	},
	placeList: {
		display: 'table',
		flex: '1',
		flexDirection: 'column',
		minWidth: '100%'
	},
	place: {
		whiteSpace: 'nowrap',
		fontSize: '14px',
		cursor: 'pointer',
		display: 'table-row'
	},
	placeItemIcon: {
		width: '12px',
		margin: '0 2px',
		':first-child': {
			marginLeft: 4,
		},
		':last-child': {
			marginRight: 4,
		},
	},
	placeItemSelectedIcon: {
		backgroundColor: 'white',
	},
	placeItemString: {
		marginLeft: 5,
		marginRight: 5,
	},
	placeItemSelected: {
		background: '#006cb0',
		color: 'white',
		outline: 0,
	},
	placeItemEven: {
		background: '#f3f3f3',
	},
	placeHidden: {
		color: '#BBB',
	},
	googleAuth: {
		justifySelf: 'end',
		marginTop: 'auto',
	},
	emptyNotice: {
		textAlign: 'center',
		margin: '30px 0',
		fontWeight: '400',
		fontSize: '20px',
		color: '#bdbdbd',
	},
	poiAndPlaceUuidText: {
		fontSize: 12,
	},
	toggleShowNavigationSwitch: {
		verticalAlign: 'middle',
		marginLeft: 8,
	}
});
