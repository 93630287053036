class Airport {
	constructor(data) {
		const { IATA, name, spreadsheetId, lnglat, mapboxSourceType, mapboxUrl, mapboxLayer } = data;
		this.IATA = IATA;
		this.name = name;
		this.spreadsheetId = spreadsheetId;
		this.lnglat = lnglat;
		this.mapboxSourceType = mapboxSourceType;
		this.mapboxUrl = mapboxUrl;
		this.mapboxLayer = mapboxLayer;
	}

	get data() {
		return {
			IATA: this.IATA,
			name: this.name,
			spreadsheetId: this.spreadsheetId,
			lnglat: this.lnglat,
			mapboxSourceType: this.mapboxSourceType,
			mapboxUrl: this.mapboxUrl,
			mapboxLayer: this.mapboxLayer,
		};
	}
}

export default Airport;
