import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import Switch from "react-switch";
import googleLogo from 'icons/google.svg';
import mapmarker from 'icons/mapmarker.svg';
import {
	authenticate as gapiAuthenticate,
	signout as gapiSignout,
	loadSheets,
	getPlaces,
	getPlace,
	setPlaceLngLat,
	checkCellsLocation,
	UUID_COL,
	NAME_COL,
	LNGLAT_COL,
	GOOGLE_PLACEID_COL,
	LAST_COL,
	getColNumber,
	setPlaceGooglePlaceId,
} from 'google-sheet';
import { css } from 'aphrodite/no-important';
import Select from 'react-select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { pushNotification } from 'Components/Notification';
import PlaceEditor from 'Components/PlaceEditor';
import { buildGeojsonFeature, buildGeojsonFeatureGM } from 'Components/Map';
import {
	selectAirport,
	selectAirportTerminal,
	selectLevel,
	selectPlace,
	unSelectPlace,
	showNavigationAction,
} from 'redux/actions';
import styles from './styles';
import _ from 'lodash';


function PlaceList(props) {

	const dispatch = useDispatch();
	const {
		gapiLoggedInStatus,
		fetchingAirports,
		airports,
		fetchingTerminals,
		airportTerminals,
		fetchingPlaces,
		levels,
		level,
		places,
		selectedPlace,
		placeCoordinates,
		selectedAirport,
		selectedTerminal,
		selectedLevel,
		selectedPoi,
	} = props;

	const selectedAirportData = selectedAirport?.data?.airportData;

	const [spreadsheetTitle, setSpreadsheetTitle] = useState(null);
	const [places_UNUSED, setPlaces] = useState([]);
	const [placesSet, setPlacesSet] = useState(null);
	const [currentPlace, setCurrentPlace] = useState(null);
	const [point, setPoint] = useState(null);
	const [placeSelected, setPlaceSelected] = useState(null);
	const [newPointLocation, setNewPointLocation] = useState(null);
	const [newPoint, setNewPoint] = useState(null);
	const [draw, setDraw] = useState(null);
	const [map, setMap] = useState(null);
	const [googlePlacesMarkers, setGooglePlacesMarkers] = useState(null);
	const [googlePlace, setGooglePlace] = useState(null);
	const [placeRefs, setPlaceRefs] = useState(null);
	const [showNavigation, setShowNavigation] = useState(false);

	const [selectedTerminalOption, setSelectedTerminalOption] = useState();
	const [selectedLevelOption, setSelectedLevelOption] = useState();

	useEffect(() => {
		if (places && !places.length)
			dispatch(unSelectPlace());
		setPlaceRefs(places.reduce((acc, value, index) => {
			acc[index] = React.createRef();
			return acc;
		}, {}));
		setPlacesSet(true);
	}, [dispatch, places]);

	useEffect(() => {
		if (!googlePlace || !currentPlace)
			return;
		(async function () {
			if (currentPlace && !currentPlace[getColNumber(GOOGLE_PLACEID_COL)]) {
				pushNotification(`Setting Google Place ID...`);
				let res = await checkCellsLocation(spreadsheetTitle);
				if (!res)
					return;
				await setPlaceGooglePlaceId(spreadsheetTitle, currentPlace[getColNumber(UUID_COL)], googlePlace.properties.googlePlaceId);
				let _places = [...places];
				_places = _places.map(place => {
					if (place[getColNumber(UUID_COL)] === currentPlace[getColNumber(UUID_COL)])
						place[getColNumber(GOOGLE_PLACEID_COL)] = googlePlace.properties.googlePlaceId;
					return place;
				});
				pushNotification(`Google Place ID set`);
				setPlaces(_places);
				setGooglePlace(null);
				map.getSource('google-places').setData({ type: 'FeatureCollection', features: [] });
			}
		}());
	}, [places, map, googlePlace, currentPlace, spreadsheetTitle]);

	useEffect(() => {
		// if(!currentPlace || !newPoint)
		// 	return;
		if (!currentPlace || !placeSelected || (!point && !newPoint))
			return;

		map.getSource('google-places').setData({ type: 'FeatureCollection', features: [] });

		let _point = point || newPoint;

		(async function () {
			if (googlePlacesMarkers)
				googlePlacesMarkers.map(marker => marker.remove());

			if (currentPlace[getColNumber(GOOGLE_PLACEID_COL)])
				return;

			let div = document.createElement('div');
			let service = new window.google.maps.places.PlacesService(div);
			let request1 = {
				query: `${currentPlace[getColNumber(NAME_COL)]}`,
				locationBias: { lng: _point.geometry.coordinates[0], lat: _point.geometry.coordinates[1], radius: 30 },
				// locationBias: {center: {lng: 2.576889, lat: 49.005306}, radius: 8000},
				fields: ['name', 'geometry', 'place_id'],
			};
			let location = new window.google.maps.LatLng(_point.geometry.coordinates[1], _point.geometry.coordinates[0]);
			let request2 = {
				keyword: `${currentPlace[getColNumber(NAME_COL)]}`,
				// locationBias: {lng: 2.576889, lat: 49.005306},
				// location: {lng: newPoint.geometry.coordinates[0], lat: newPoint.geometry.coordinates[1]},
				location,
				radius: '20',
				// fields: ['name', 'geometry', 'place_id'],
			};
			// service.nearbySearch(request2, function (results, status) {
			service.findPlaceFromQuery(request1, function (results, status) {
				let placesMarkers = {
					"type": "FeatureCollection",
					"features": [],
				};
				if (status === window.google.maps.places.PlacesServiceStatus.OK) {
					console.log('results', results);
					for (let i = 0; i < results.length; i++) {
						console.log(results[i]);
						// placesMarkers.push(new mapboxgl.Marker()
						// 	.setLngLat([results[i].geometry.location.lng(), results[i].geometry.location.lat()])
						// 	.addTo(map)
						// );
						let gmplace = buildGeojsonFeatureGM(results[i]);
						placesMarkers.features.push(gmplace);
					}
					// setGooglePlacesMarkers(placesMarkers);
					map.getSource('google-places').setData(placesMarkers);
				}
			});
		}());
		// }, [currentPlace, newPoint, googlePlacesMarkers, draw, map]);
	}, [currentPlace, point, newPoint, placeSelected, googlePlacesMarkers, draw, map]);

	useEffect(() => {
		(async function () {
			if (newPoint) {
				if (currentPlace && !currentPlace[getColNumber(LNGLAT_COL)]) {
					pushNotification(`Placing marker...`);
					let res = await checkCellsLocation(spreadsheetTitle);
					if (!res)
						return;
					draw.setFeatureProperty(newPoint.id, 'name', currentPlace[getColNumber(NAME_COL)])
					await setPlaceLngLat(spreadsheetTitle, currentPlace[getColNumber(UUID_COL)], newPoint.geometry.coordinates[0], newPoint.geometry.coordinates[1]);
					pushNotification(`Marker placed`);
					let _places = [...places];
					_places = _places.map(place => {
						if (place[getColNumber(UUID_COL)] === currentPlace[getColNumber(UUID_COL)])
							place[getColNumber(LNGLAT_COL)] = `[${newPoint.geometry.coordinates[0]}, ${newPoint.geometry.coordinates[1]}]`;
						return place;
					});
					setPlaces(_places);
					draw.delete(newPoint.id);
					let _newPlace = buildGeojsonFeature(currentPlace);
					draw.add(_newPlace);
					setPoint(newPoint);
					setNewPoint(null);
				}
			}
		})();
	}, [newPoint, places, currentPlace, spreadsheetTitle, draw]);

	useEffect(() => {
		if (!draw || !point || !places)
			return;
		let index = null;
		let place = places.find((place, _index) => {
			if (place[getColNumber(UUID_COL)] === point.id) {
				index = _index;
				return true;
			}
			return false;
		});
		if (place) {
			setCurrentPlace(place);
			setPlaceSelected(true);
			placeRefs[index].current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [places, point, draw, placeRefs]);

	useEffect(() => {
		if (!selectedPlace)
			return;
		let index = null;
		let place = places.find((place, _index) => {
			if (selectedPlace.uuid === place.uuid) {
				index = _index;
				return true;
			}
			return false;
		});
		if (place) {
			placeRefs[index].current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [selectedPlace, placeRefs, places]);

	useEffect(() => {
		if (!draw || !currentPlace || !newPointLocation || !places)
			return;

		(async function () {
			pushNotification(`Updating marker...`);
			await setPlaceLngLat(spreadsheetTitle, currentPlace[getColNumber(UUID_COL)], newPointLocation.geometry.coordinates[0], newPointLocation.geometry.coordinates[1]);
			pushNotification(`Marker updated`);
			setNewPointLocation(null);
		})();
	}, [places, currentPlace, newPointLocation, spreadsheetTitle, draw]);

	useEffect(() => {
		if (!draw)
			return;
		let features = [];
		for (let place of places) {
			if (!place[13])
				continue;
			features.push(buildGeojsonFeature(place));
		}
		draw.add({
			type: 'FeatureCollection',
			features,
		});
	}, [places, draw]);

	useEffect(() => {
		setSelectedLevelOption({
			value: level,
			label: level,
		});
	}, [level]);

	function onSelectAirport(airportIATA) {
		if (!airportIATA)
			return;
		setSelectedTerminalOption(null);
		dispatch(selectAirport(airportIATA));
	}

	function onSelectAirportTerminal(terminal) {
		if (!terminal)
			return;
		setSelectedTerminalOption({
			value: terminal,
			label: terminal,
		});
		dispatch(selectAirportTerminal(terminal));
	}

	function onSelectLevel(level) {
		setSelectedLevelOption({
			value: level,
			label: level,
		});
		dispatch(selectLevel(level));
	}

	function onPlaceClick(place, index) {
		dispatch(selectPlace(place));
	}

	async function signout() {
		await gapiSignout();
	}

	async function authenticate() {
		await gapiAuthenticate();
	}

	function toggleShowNavigation() {
		const _showNavigation = !showNavigation;
		dispatch(showNavigationAction(_showNavigation));
		setShowNavigation(_showNavigation);
	}

	return (
		<div id='placelist' className={css(styles.container)}>
			<div className={css(styles.inner)}>
				<Autocomplete
					className={css(styles.airportSelect, styles.selectBox)}
					size="small"
					options={Object.keys(airports).map((IATA, index) => ({
						value: IATA,
						label: `${IATA} - ${airports[IATA].name}`,
					}))}
					onChange={(event, value) => value && onSelectAirport(value.value)}
					getOptionLabel={option => option.label}
					renderInput={params => (
						<TextField {...params} label="Aéroport" variant="outlined" fullWidth
						/>
					)}
				/>
				<div className={css(styles.terminalLevelsContainer)}>
					{/* <Autocomplete
						className={css(styles.terminalSelect, styles.selectBox)}
						size="small"
						value={selectedTerminalOption}
						options={airportTerminals.map((airportTerminal, index) => ({
							value: airportTerminal,
							label: airportTerminal,
						}))}
						onChange={(event, value) => value && onSelectAirportTerminal(value.value)}
						getOptionLabel={option => option.label}
						renderInput={params => (
							<TextField {...params} label="Terminal" variant="outlined" fullWidth />
						)}
					/> */}
					<Autocomplete
						className={css(styles.levelSelect, styles.selectBox)}
						size="small"
						value={selectedLevelOption}
						options={_.chain(selectedAirportData?.structures)
							.reduce((acc, structure) => {
								const levels = new Set([...acc, ..._.map(structure.levels, 'ordinal')]);
								return _.map(Array.from(levels), l => parseInt(l));
							}, [])
							.sortBy()
							.map(level => ({
								value: level,
								label: `${level}`
							}))
							.value()
						}
						onChange={(event, value) => value && onSelectLevel(value.value)}
						getOptionLabel={option => option.label || ''}
						renderInput={params => (
							<TextField {...params} label="Niveau" variant="outlined" fullWidth />
						)}
					/>
				</div>
				<div className={css(styles.placelistContainer)}>
					<div className={css(styles.placeList)}>
						{fetchingPlaces &&
							<div>Chargement places...</div>
						}
						{(selectedTerminal.name && !fetchingPlaces && !places.length) &&
							<div className={css(styles.emptyNotice)}>Aucune place</div>
						}
						{places.map((place, index) =>
							<span key={index}
								ref={placeRefs[index]}
								onClick={() => onPlaceClick(place, index)}
								className={css(
									styles.place,
									(selectedPlace && selectedPlace.uuid === place.uuid ? styles.placeItemSelected : (index % 2 === 0 ? styles.placeItemEven : null)),
									place.level !== selectedLevel ? styles.placeHidden : null
								)}
							>
								<span>
									{place.lnglat &&
										<img className={css(styles.placeItemIcon, (selectedPlace && selectedPlace.uuid === place.uuid ? styles.placeItemSelectedIcon : null))} src={mapmarker} alt=""></img>
									}
									{place.googlePlaceId &&
										<img className={css(styles.placeItemIcon, (selectedPlace && selectedPlace.uuid === place.uuid ? styles.placeItemSelectedIcon : null))} src={googleLogo} alt=""></img>
									}
								</span>
								<span className={css(styles.placeItemString)}>
									{place.name}&nbsp;
											({place.category})&nbsp;
											[{place.family}]{' - '}
									{place.zone}&nbsp;-&nbsp;Level {place.level}&nbsp;-&nbsp;
											{place.near}{' - '}
									{place.uuid.slice(-5)}
								</span>
							</span>
						)}
					</div>
				</div>
				{/* <PlaceEditor /> */}
				{/* <fieldset className={css(styles.googleAuth)}>
					<legend>Google Sheets&nbsp;<span className={'coloredCircle ' + (gapiLoggedInStatus ? 'coloredCircleGreen' : 'coloredCircleRed')}></span>&nbsp;</legend>
					{gapiLoggedInStatus ?
						<button id='signout_button' onClick={signout}>Signout</button>
						:
						<button id='authorize_button' onClick={authenticate}>Authenticate</button>
					}
				</fieldset> */}
				{!_.isNil(selectedPoi) &&
					<div>
						<div>Poi sélectionné :</div>
						<div>Poi uuid : <span className={css(styles.poiAndPlaceUuidText)}>{selectedPoi.poi.uuid}</span></div>
						<div>Place uuid : <span className={css(styles.poiAndPlaceUuidText)}>{selectedPoi.place.uuid}</span></div>
					</div>
				}
				<div>
					<label>
						<span>Afficher la navigation</span>
						<Switch
							className={css(styles.toggleShowNavigationSwitch)}
							onChange={toggleShowNavigation}
							checked={showNavigation}
							uncheckedIcon={false}
							checkedIcon={false}
							boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
							width={36}
							height={16}
							handleDiameter={22}
						/>
					</label>
				</div>
			</div>
		</div>
	);
}

PlaceList.propTypes = {
	gapiLoggedInStatus: PropTypes.bool,
	airports: PropTypes.object,
	fetchingAirports: PropTypes.bool,
	airportTerminals: PropTypes.array,
	fetchingTerminals: PropTypes.bool,
	selectedAirport: PropTypes.object,
	selectedTerminal: PropTypes.object,
	selectedLevel: PropTypes.string,
	selectedPoi: PropTypes.string,
	levels: PropTypes.array,
	level: PropTypes.string,
	places: PropTypes.array,
	fetchingPlaces: PropTypes.bool,
	selectedPlace: PropTypes.object,
	placeCoordinates: PropTypes.array,
}

export default connect(state => ({
	gapiLoggedInStatus: state.gapi.gapiLoggedInStatus,
	airports: state.airports.airports,
	fetchingAirports: state.airports.fetchingAirports,
	airportTerminals: state.airports.selectedAirport.terminals,
	fetchingTerminals: state.airports.selectedAirport.fetchingTerminals,
	selectedAirport: state.airports.selectedAirport,
	selectedTerminal: state.airports.selectedAirport.selectedTerminal,
	selectedLevel: state.airports.selectedAirport.selectedTerminal.selectedLevel,
	levels: state.places.fields.levels,
	level: state.airports.selectedAirport.selectedTerminal.selectedLevel,
	places: state.airports.selectedAirport.selectedTerminal.places,
	fetchingPlaces: state.airports.selectedAirport.selectedTerminal.fetchingPlaces,
	selectedPlace: state.places.selectedPlace,
	placeCoordinates: state.map.placeCoordinates,
	selectedPoi: state.map.selectedPoi,
}))(PlaceList);
