import { createReducer } from '@reduxjs/toolkit'
import {
	setColorsAction
} from 'redux/colorEditorActions';

const initialState = {
	colors: {},
};

const colorEditorReducer = createReducer(initialState, {
	[setColorsAction]: (state, action) => {
		state.colors = action.payload;
	},
});

export default colorEditorReducer;
