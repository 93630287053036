import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
	container: {
		position: 'absolute',
		top: 40,
		right: 20,
	},
	categoryList: {
		listStyleType: 'none',
		backgroundColor: 'white',
	},
	category: {
		cursor: 'pointer',
		lineHeight: 2,
	},
	categorySelectedMarker: {
		position: 'absolute',
		left: 11,
	},
	colorPaletteIcon: {
		position: 'absolute',
		cursor: 'pointer',
		right: 0,
		top: -30,
		width: 30,
		height: 30,
	}
});
