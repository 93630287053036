import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import DrawRectangle from '@geostarters/mapbox-gl-draw-rectangle-assisted-mode';
import Map from './Map'
import config from 'config';
import _styles, { mapboxDrawStyles } from './styles';
import { css } from 'aphrodite';
import * as rasterCategoryIcons from 'icons/raster-category-icons';
import _ from 'lodash';


mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;

function MapInitializer(props) {

	const { modeSetCoordinates } = props;
	const mapContainer = useRef();
	const [map, setMap] = useState();
	const [draw, setDraw] = useState();
	const [fadeInOut, setFadeInOut] = useState(true);
	const fadeDelay = 500;
	const styles = _styles(fadeDelay);

	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/flyter/ck3s73p0p1j151cnufx1zemcj',
			center: [-8.0961547, 17.628082],
			zoom: 2,
		});

		const modes = MapboxDraw.modes;
		modes.draw_assisted_rectangle = DrawRectangle;

		const draw = new MapboxDraw({
			displayControlsDefault: false,
			controls: {
				// point: true,
				// polygon: true,
				// trash: true,
				// combine_features: true,
				// uncombine_features: true,
			},
			modes,
			// defaultMode: 'draw_assisted_rectangle'
			userProperties: true,
			styles: mapboxDrawStyles
		});
		map.on('load', () => {
			// map.addSource('gmaps-source', {
			// 	'type': 'raster',
			// 	'tiles': ['https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'],
			// 	'tileSize': 250
			// })
			// map.addLayer({
			// 	'id': 'gmaps',
			// 	'type': 'raster',
			// 	'source': 'gmaps-source'
			// })

			map.addControl(draw);
			setDraw(draw);
			_.forEach(rasterCategoryIcons, (icon, iconName) => {
				map.loadImage(icon, (error, image) => {
					map.addImage(iconName, image);
				})
			})
			setMap(map);
		});
	}, []);

	const fadeMap = useRef((inOut) => setFadeInOut(inOut));

	return (
		<div
			id='map'
			style={styles.map._definition}
			className={`${css(
				fadeInOut ? styles.fadeIn : styles.fadeOut,
			)} ${modeSetCoordinates ? 'mouse-add' : ''}`}
			ref={mapContainer}
		>
			{map &&
				<Map
					map={map}
					draw={draw}
					fadeMap={fadeMap.current}
					fadeDelay={fadeDelay}
				/>
			}
		</div>
	);
}

MapInitializer.propTypes = {
	modeSetCoordinates: PropTypes.boolean,
}

export default connect(state => ({
	modeSetCoordinates: state.map.modeSetCoordinates,
}))(MapInitializer);
export { buildGeojsonFeature, buildGeojsonFeatureGM } from './Map';

