import { createReducer } from '@reduxjs/toolkit'
import {
	fetchCategoriesAction,
	fetchCategoriesSuccessAction
} from 'redux/actions';


const initialState = {
	categories: [],
};

const placesReducer = createReducer(initialState, {
	[fetchCategoriesSuccessAction]: (state, action) => {
		state.categories = action.payload;
	},
});

export default placesReducer;
