import { createReducer } from '@reduxjs/toolkit'
import { gapiSetLoggedInStatus } from '../actions';

const initialState = {
	gapiLoggedInStatus: false,
};

const gapiReducer = createReducer(initialState, {
	[gapiSetLoggedInStatus]: (state, action) => {
		const status = action.payload;
		state.gapiLoggedInStatus = status;
	},
})

export default gapiReducer;
