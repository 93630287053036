import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'aphrodite/no-important';
import styles from './styles';
import { connect } from 'react-redux';

function getPlaceString(place) {
	return (
		<>
			{place[4]}&nbsp;
			({place[3]})&nbsp;
			[{place[2]}]
			<br />
			{place[6]}&nbsp;-&nbsp;Level {place[7]}&nbsp;-&nbsp;
			{place[8]}
		</>
	);
}

function HeaderInfo(props) {

	const {
		selectedAirport,
		selectedTerminal,
		blinkTerminalName,
		currentPlace,
	} = props;

	return (
		<div className={css(styles.container)}>
			{selectedAirport.name &&
				<>
					<div>{selectedAirport.IATA} - {selectedAirport.name}</div>
					{selectedTerminal.name &&
						<div className={css(styles.info)}>
							<div className={css(styles.terminalName, blinkTerminalName && styles.blinkTerminalName)}>{selectedTerminal.name}</div>
							{currentPlace &&
								<div id="headerInfoPlaceName">
									{getPlaceString(currentPlace, true)}
								</div>
							}
						</div>
					}
				</>
			}
		</div>
	);
}

const mapStateToProps = (state, ownProps) => {
	return {
		selectedAirport: state.airports.selectedAirport,
		selectedTerminal: state.airports.selectedAirport.selectedTerminal,
		// venueIsSet: state.airports.venueIsSet,
		// currentPlace: state.places.currentPlace,
	}
}

HeaderInfo.propTypes = {
	selectedAirport: PropTypes.object,
	selectedTerminal: PropTypes.object,
	blinkTerminalName: PropTypes.boolean,
	currentPlace: PropTypes.array,
}

export default connect(mapStateToProps)(HeaderInfo);
