class Place {
	constructor(data) {
		const { uuid, metaPlaceUuid, name, family, category, zone, level, near, googlePlaceId, lnglat } = data;
		this.uuid = uuid;
		this.metaPlaceUuid = metaPlaceUuid;
		this.name = name;
		this.family = family;
		this.category = category;
		this.zone = zone;
		this.level = level;
		this.near = near;
		this.googlePlaceId = googlePlaceId;
		this.lnglat = lnglat;
	}

	get data() {
		return {
			uuid: this.uuid,
			metaPlaceUuid: this.metaPlaceUuid,
			name: this.name,
			family: this.family,
			category: this.category,
			zone: this.zone,
			level: this.level,
			near: this.near,
			googlePlaceId: this.googlePlaceId,
			lnglat: this.lnglat,
		};
	}
}

export default Place;

