import { put, call, takeEvery, select } from 'redux-saga/effects'
import {
	selectAirportAction,
	// fetchTerminalsAction,
	// fetchTerminalsSuccessAction,
	selectAirportTerminalAction,
	fetchTerminalPlacesAction,
	fetchTerminalPlacesSuccessAction,
} from 'redux/actions';
import {
	fetchTerminals as fetchTerminalsApi,
	fetchTerminalPlaces as fetchTerminalPlacesApi,
} from 'Api';

function* fetchTerminals(action) {
	yield put(fetchTerminalsAction());
	let terminals = yield call(fetchTerminalsApi, action.payload);
	yield put(fetchTerminalsSuccessAction(terminals));
}

function* fetchTerminal(action) {
	let airport = yield select(state => state.airports.selectedAirport);
	yield put(fetchTerminalPlacesAction());
	let places = yield call(fetchTerminalPlacesApi, airport, action.payload);
	yield put(fetchTerminalPlacesSuccessAction(places));
}

export default function* watchSelectAirport() {
	// yield takeEvery(selectAirportAction, fetchTerminals);
	// yield takeEvery(selectAirportTerminalAction, fetchTerminal);
}


