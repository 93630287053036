const actionTypes = {};
for (let actionType of [
	"GAPI_LOGGED_IN_STATUS",
	"FETCH_APP_CONFIG",
	"FETCH_APP_CONFIG_SUCCESS",
	"FETCH_AIRPORTS",
	"FETCH_AIRPORTS_SUCCESS",
	"FETCH_AIRPORT",
	"FETCH_AIRPORT_SUCCESS",
	"FETCH_PLACES",
	"FETCH_PLACES_SUCCESS",
	"FETCH_CATEGORIES",
	"FETCH_CATEGORIES_SUCCESS",
	"FETCH_FIELDS",
	"FETCH_FIELDS_SUCCESS",
	"SELECT_AIRPORT",
	"FETCH_TERMINALS",
	"FETCH_TERMINALS_SUCCESS",
	"SELECT_TERMINAL",
	"FETCH_TERMINAL_PLACES",
	"FETCH_TERMINAL_PLACES_SUCCESS",
	"SELECT_LEVEL",
	"SELECT_PLACE",
	"UNSELECT_PLACE",
	"SET_MAP_MODE_SET_PLACE_LOCATION",
	"SET_COORDINATES",
	"SET_SELECTED_PLACE_LOCATION",
	"PUT_SELECTED_PLACE_LOCATION",
	"PUT_SELECTED_PLACE_LOCATION_SUCCESS",
	"CREATE_PLACE",
	"PUT_NEW_PLACE",
	"PUT_NEW_PLACE_SUCCESS",
	"PUT_EXISTING_PLACE",
	"PUT_EXISTING_PLACE_SUCCESS",
	"APPEND_NEW_PLACE",
	"DELETE_PLACE",
	"DELETE_PLACE_SUCCESS",

	"SELECT_POI",
	"FETCH_SECURITY_CHECKPOINTS",
	"FETCH_SECURITY_CHECKPOINTS_SUCCESS",

	"PLACE_EDITOR.SET_PLACE",
	"PLACE_EDITOR.SET_PLACE_COORDINATES",

	"COLOR_EDITOR_SET_COLORS",

	"SHOW_NAVIGATION",
])
	actionTypes[actionType] = actionType;

export default actionTypes;

