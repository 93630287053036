import { combineReducers } from 'redux';
import places from './places';
import categories from './categories';
import appConfig from './appConfig';
import airports from './airports';
import gapi from './gapi';
import map from './map';
import placeEditor from './placeEditor';
import colorEditor from './colorEditor';

export default combineReducers({
	appConfig,
	places,
	categories,
	airports,
	gapi,
	map,
	placeEditor,
	colorEditor,
});

